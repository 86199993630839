import React from "react";
import {Card, CardHeader, TextField, withStyles} from "@material-ui/core";
import {DELETE_SECTION, UPDATE_SECTION_TITLE} from "../reducers/ActionTypes";
import connect from "react-redux/es/connect/connect";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from "react-router-dom";
import UILink from '@material-ui/core/Link';
import {getCourseBySlug} from "../selectors";


const styles = theme => ({
    sectionTitleCard: {
        zIndex: theme.zIndex.appBar - 2,
    },
    sectionTitleText: {
        margin: 0,
    },
});

const mapStateToProps = (state, ownProps) => ({
    courseSlug: ownProps.match.params.course_slug,
    course: getCourseBySlug(state, ownProps.match.params.course_slug),
});

const mapDispatchToProps = (dispatch) => ({
    updateSectionTitle: (pk) => (event) => {
        event.preventDefault();
        dispatch({type: UPDATE_SECTION_TITLE, pk, title: event.target.value});
    },
    deleteSection: (pk, course_slug) => dispatch({type: DELETE_SECTION, pk, course_slug}),
});

class SectionTitleCard extends React.Component {

    render() {
        const {classes, readOnly, section, course, updateSectionTitle} = this.props;
        const courseOwner = course.owner.username;

        if (!section) {
            return null
        }

        const title = section.title;
        const pk = section.pk;
        const breadcrumbs = (
            <div>
                <Breadcrumbs aria-label="Breadcrumb" separator="›">
                    <UILink color="inherit" component={Link} to={`/@${courseOwner}`}>
                        {`@${courseOwner}`}
                    </UILink>
                    <p className={classes.sectionTitleText}>
                        {course.title}
                    </p>
                    {!readOnly &&
                        <TextField
                            id="standard-full-width"
                            placeholder="Title"
                            value={title}
                            onChange={updateSectionTitle(pk)}
                            margin="none"
                            style={{width: '280px'}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                        />
                    }
                    {readOnly &&
                        <p className={classes.sectionTitleText}>
                            {title}
                        </p>
                    }
                </Breadcrumbs>
            </div>
        );

        return (
            <Card square={true} className={classes.sectionTitleCard}>
                <CardHeader
                    title={breadcrumbs}
                />
            </Card>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(SectionTitleCard));