import React from 'react';
import 'intersection-observer';
import Typography from "@material-ui/core/Typography";
import {insertYouTube} from "./YouTubePlugin";
import ReactPlayer from 'react-player';
import {withStyles} from "@material-ui/core";
import Observer from 'react-intersection-observer';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {formatSeconds} from "../../utils";
import Fab from "@material-ui/core/Fab";
import SplitIcon from '@material-ui/icons/CallSplit';
import Tooltip from "@material-ui/core/Tooltip";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";

const styles = theme => ({
    videoInDocument: {
        [theme.breakpoints.only('xs')]: {
            height: '200px', maxHeight: '200px',
        },
        [theme.breakpoints.only('sm')]: {
            height: '300px', maxHeight: '300px',
        },
        [theme.breakpoints.up('md')]: {
            height: '400px', maxHeight: '400px',
        },
    },
    miniVideo: {
        position: 'fixed',
        zIndex: 10000,
        bottom: 0,
        [theme.breakpoints.only('xs')]: {
            height: '120px',
            maxHeight: '120px',
            right: 0,
        },
        [theme.breakpoints.up('sm')]: {
            height: '200px',
            maxHeight: '200px',
            right: 0,
        },
    },
    durationText: {
        backgroundColor: 'black',
        color: 'white',
        paddingLeft: '0.3rem'
    },
    closeButton: {
        position: 'absolute',
        height: '20px',
        width: '20px',
        color: '#E9E9E9',
        top: 0,
        right: 0,
    },
    videoActions: {
        justifyContent: 'center',
    },
    splitIcon: {
        margin: theme.spacing(1)
    }
});

class YouTubeNode extends React.Component {
    state = {
        playing: false,
        started: false,
        visibility: false,
        hideMiniVideo: false,
    };

    playerRef = player => {
        this.player = player;
    };

    onDuration = (duration) => {
        this.setState({duration})
    };

    splitVideo = () => {
        const currentTime = this.player.getCurrentTime();
        const {editor, node} = this.props;

        const {videoId, start, end} = this.getVideoProps();


        editor.moveToEndOfNode(node);
        insertYouTube(editor, videoId, start, currentTime);
        insertYouTube(editor, videoId, currentTime, end);

        editor.removeNodeByKey(node.key);
    };

    handlePlay = () => {
        this.setState({playing: true, hideMiniVideo: false})
    };

    handlePause = () => {
        this.setState({playing: false})
    };

    handleStart = () => {
        this.setState({started: true})
    };

    handleEnd = () => {
        this.setState({playing: false});

        const {start} = this.getVideoProps();
        this.player.seekTo(start);
    };

    getVideoProps = () => {
        const node = this.props.node;

        const videoId = node.data.get("src");
        const start = node.data.get("start");
        const end = node.data.get("end");

        return {videoId, start, end}
    };

    handleChange = visibility => {
        this.setState({visibility});
    };

    handleForceHide = () => {
        this.setState({hideMiniVideo: true})
    };

    render() {
        const {editor, classes} = this.props;
        const readOnly = editor.props.readOnly;
        const {videoId, start, end} = this.getVideoProps();
        const {visibility, playing, started, hideMiniVideo} = this.state;

        const showMiniVideo = !hideMiniVideo && started && !visibility;
        const showSplitButton = !readOnly && !playing && started && visibility;

        return (
            <Observer onChange={this.handleChange}>
                {!!this.state.duration &&
                <div className={classes.durationText}>
                    {(start || end) &&
                    <Typography variant="caption">
                        Fragment: {formatSeconds(start)} - {!end ? formatSeconds(this.state.duration) : formatSeconds(end)}
                    </Typography>
                    }
                    {!end && !start &&
                    <Typography variant="caption">
                        Duration: {formatSeconds(this.state.duration)}
                    </Typography>
                    }
                </div>
                }

                <div className={classes.videoInDocument}>
                    <div className={showMiniVideo ? classes.miniVideo : classes.videoInDocument}>
                        <ReactPlayer
                            ref={this.playerRef}
                            url={`https://www.youtube.com/embed/${videoId}?start=${start}&end=${end}`}
                            width='100%'
                            height='100%'
                            controls={true}
                            playing={playing}
                            onStart={this.handleStart}
                            onPlay={this.handlePlay}
                            onPause={this.handlePause}
                            onEnded={this.handleEnd}
                            onDuration={this.onDuration}
                        />
                        {showMiniVideo &&
                        <IconButton
                            variant="round"
                            size="small"
                            className={classes.closeButton}
                            onClick={this.handleForceHide}
                        >
                            <CloseIcon/>
                        </IconButton>}
                    </div>
                </div>


                <CardActions className={classes.videoActions}>
                    <Collapse direction="down" in={showSplitButton} mountOnEnter unmountOnExit>
                        {this.player &&
                        <div>
                            <Tooltip
                                title="Create two video fragments out of the video">
                                <Fab variant="extended"
                                     size="small"
                                     onClick={this.splitVideo}
                                >
                                    <SplitIcon className={classes.splitIcon}/>
                                    Split the video at {formatSeconds(this.player.getCurrentTime())}
                                </Fab>
                            </Tooltip>
                        </div>
                        }
                    </Collapse>
                </CardActions>
            </Observer>
        )
    }
}

export default withStyles(styles)(YouTubeNode);
