export default function QueryPlugin(options) {
    return {
        queries: {
            isBlockActive(editor, type) {
                return editor.value.blocks.some(block => block.type === type);
            },
            isInlineActive(editor, type) {
                return editor.value.inlines.some(inline => inline.type === type);
            },
            isMarkActive(editor, type) {
                // TODO: check if this is still relevant
                // This is to prevent crashing when selecting question at the end of editor:
                try {
                    return editor.value.activeMarks.some(mark => mark.type === type);
                } catch {
                    console.log("Warning: activeMarks failed.");
                    return false;
                }
            },
        },
    }
}
