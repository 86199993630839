import {
    CLOSE_LOGIN,
    CLOSE_REGISTER,
    FINISH_SIGN,
    INITIALIZE_USER,
    LOGOUT,
    OPEN_LOGIN,
    OPEN_REGISTER,
    SET_AUTH_ERROR,
    SET_USERNAME,
    SIGN_IN,
    SIGN_UP,
    OPEN_ONBOARDING,
    CLOSE_ONBOARDING,
    SET_EMBEDDED_TRUE
} from "./ActionTypes";


const defaultState = {
    loginOpened: false,
    registerOpened: false,
    errorText: "",
    onboardingOpened: false,
    embedded: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_USERNAME:
            return {
                ...state,
                username: action.username,
            };
        case SIGN_UP:
        case SIGN_IN:
        case INITIALIZE_USER:
            return {
                ...state,
                isSigning: true,
                errorText: "",
            };
        case FINISH_SIGN:
            return {
                ...state,
                isSigning: false,
            };
        case OPEN_LOGIN:
            return {
                ...state,
                loginOpened: true,
            };
        case CLOSE_LOGIN:
            return {
                ...state,
                loginOpened: false,
                errorText: "",
            };
        case OPEN_REGISTER:
            return {
                ...state,
                registerOpened: true,
            };
        case CLOSE_REGISTER:
            return {
                ...state,
                registerOpened: false,
            };
        case SET_AUTH_ERROR:
            return {
                ...state,
                errorText: action.errorText,
            };
        case LOGOUT:
            return {};
        case OPEN_ONBOARDING:
            return {
                ...state,
                onboardingOpened: true,
            };
        case CLOSE_ONBOARDING:
            return {
                ...state,
                onboardingOpened: false,
            };
        case SET_EMBEDDED_TRUE:
            return {
                ...state,
                embedded: true,
            };
        default:
            return state;
    }
};