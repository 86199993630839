import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from "../styles/dialogStyle";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


class SectionEmbedDialog extends React.Component {
    onSubmit = (event) => {
        event.preventDefault();
    };

    render() {
        const {section, handleClose, open} = this.props;

        const pk = section.pk;
        const courseSlug = section.course.slug;

        const {protocol, hostname, port} = window.location;

        const embedHTML = `<iframe src="${protocol}//${hostname}${!!port ? `:${port}` : ``}/embed/${courseSlug}/${pk}"></iframe>`;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {margin: "24px"}
                }}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    HTML embed
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Use this HTML to embed <b>{section.title}</b> to your website
                    </DialogContentText>
                    <form onSubmit={this.onSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="input">Embed HTML</InputLabel>
                            <Input id="input" name="input" autoFocus readOnly={true} multiline
                                   value={embedHTML}
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        onClick={handleClose}
                    >
                        Go back
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SectionEmbedDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SectionEmbedDialog);