import {DELETE_ANSWERED_REPETITION, SET_REPETITIONS, SET_REPETITIONS_COUNT,} from "./ActionTypes";


const defaultState = {
    repetitions: null,
    repetitionsCount: 0
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_REPETITIONS:
            return {...state, repetitions: action.repetitions};

        case SET_REPETITIONS_COUNT:
            return {...state, repetitionsCount: action.repetitionsCount};

        case DELETE_ANSWERED_REPETITION:
            if (!state.repetitions) {
                return defaultState;
            }

            return {...state, repetitions: state.repetitions.filter(r => r.item.pk !== action.pk)};

        default:
            return state;
    }
};