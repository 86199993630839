import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

const styles = theme => ({
    root: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    imageWithTopMargin: {
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
        marginTop: 20,
    },
    fullWidthTextBlock: {
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

function getSteps() {
    return ['Create a course', 'Make study notes', 'Explore other\'s courses',
    'Take other\'s courses', 'Review questions', 'Want to know more?'];
}

function getStepText(step) {
    switch (step) {
        case 0:
            return `Everything is a course on LearnItFast. Create your first course to start learning. \n
            To see all your courses use "My courses" button.`;
        case 1:
            return `You can put different types of content in your courses including text, images and videos. 
            There is also a special type - test questions. \n
            Add a question to your course and we will remind you to review it in 5 minutes, 1 day, 3 days and so on.`;
        case 2:
            return `You can explore other's courses by going to "Explore" page. Bookmark courses you like. 
            They will appear in "Bookmarks" tab on your courses page.`;
        case 3:
            return `Take other's courses. Answer questions in them. They will appear in your repetitions in 1 day, 
            3 days and so on.`;
        case 4:
            return `You can see how many questions you have to review next to the "Repeat" button. \n
            Go to "Repeat" to test and strengthen your knowledge. \n
            Think (or write down) of the answer before clicking on "Show answer". Check if you were correct.`;
        case 5:
            return (
                <React.Fragment>
                    You're all set. If you want to know more about spaced repetitions or how to use LearnItFast in your
                    learning you can visit our FAQ. Also you can read articles from our selection of <Link
                    to="/articles" style={{textDecoration: "none"}} target="_blank" rel="noopener noreferrer">popular
                    articles</Link>.
                </React.Fragment>
            );
        default:
            return 'Unknown step';
    }
}

function getStepImage(step) {
    switch (step) {
        case 0:
            return require('../images/onboarding1.gif');
        case 1:
            return require('../images/onboarding3.gif');
        case 2:
            return require('../images/onboarding2.gif');
        case 3:
            return require('../images/landing2.gif');
        case 4:
            return require('../images/onboarding5.gif');
        case 5:
            //return require('../images/landing1.gif');
            return "";
        default:
            return 'Unknown step';
    }
}

class OnboardingStepper extends React.Component {
    state = {
        activeStep: 0,
    };

    handleNext = () => {
        const steps = getSteps();
        const {activeStep} = this.state;
        const {closeOnboarding} = this.props;
        if (activeStep === steps.length - 1) {
            closeOnboarding();
        }
        else {
            this.setState(state => ({
                activeStep: state.activeStep + 1,
            }));
        }
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    render() {
        const {classes} = this.props;
        const steps = getSteps();
        const {activeStep} = this.state;

        return (
            <div className={classes.fullWidthTextBlock}>
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>
                                    <Typography variant="h6">
                                        {label}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <Typography>{getStepText(index)}</Typography>
                                    <img src={getStepImage(index)} alt=""
                                         className={classes.imageWithTopMargin}/>
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={this.handleBack}
                                                className={classes.button}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleNext}
                                                className={classes.button}
                                            >
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </div>
        );
    }
}

OnboardingStepper.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(OnboardingStepper);