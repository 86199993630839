import React from "react";
import {Card, withStyles} from "@material-ui/core";
import {DELETE_SECTION, MOVE_SECTION} from "../reducers/ActionTypes";
import connect from "react-redux/es/connect/connect";
import Button from "@material-ui/core/Button";
import {NavLink} from 'react-router-dom';
import SectionEmbedDialog from "./SectionEmbedDialog";
import SectionMoveDialog from "./SectionMoveDialog";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import {withRouterAndRef} from "./utils";

const RouterNavLink = withRouterAndRef(NavLink);

const styles = theme => ({
    sectionActionsCard: {
        zIndex: theme.zIndex.appBar - 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        maxWidth: "100%",
        padding: "8px",
    },
    button: {}
});

const mapStateToProps = (state) => ({
    currentUser: state.auth.username
});

const mapDispatchToProps = (dispatch) => ({
    deleteSection: (pk, course_slug) => dispatch({type: DELETE_SECTION, pk, course_slug}),
    moveSection: (pk, course_slug, moveto) => dispatch({type: MOVE_SECTION, pk, course_slug, moveto}),
});

class SectionTitleCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            embedDialog: false,
            deleteOpen: false,
            moveOpen: false,
        };
    }

    handleCloseEmbed = () => {
        this.setState({embedDialog: false});
    };

    handleCloseDelete = () => {
        this.setState({deleteOpen: false});
    };

    handleCloseMove = () => {
        this.setState({moveOpen: false});
    };

    render() {
        const {classes, currentUser, editMode, section, deleteSection, moveSection} = this.props;

        if (!section) {
            return null
        }
        const pk = section.pk;
        const courseSlug = section.course.slug;

        const sectionOwner = section.course.owner.username;
        const userOwnSection = sectionOwner === currentUser;

        return (
            <Card square={true}>
                <div className={classes.sectionActionsCard}>
                    <Button
                        color="primary"
                        size="small"
                        component={RouterNavLink}
                        to={`/@${sectionOwner}/${courseSlug}/${pk}`}
                        className={classes.button}
                        disabled={!editMode}
                    >
                        Preview
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        component={RouterNavLink}
                        to={`/@${sectionOwner}/${courseSlug}/edit/${pk}`}
                        className={classes.button}
                        disabled={editMode}
                    >
                        {userOwnSection ? 'Edit' : 'Copy Mode'}
                    </Button>
                    {userOwnSection &&
                        <Button
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={() => this.setState({moveOpen: true})}
                        >
                            Move
                        </Button>
                    }
                    <SectionMoveDialog
                        open={this.state.moveOpen}
                        closeHandler={this.handleCloseMove}
                        actionHandler={(moveto) => moveSection(pk, courseSlug, moveto)}
                    />
                    <Button
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={() => this.setState({embedDialog: true})}
                    >
                        Embed
                    </Button>
                    <SectionEmbedDialog
                        open={this.state.embedDialog}
                        handleClose={this.handleCloseEmbed}
                        section={section}
                        aria-labelledby="draggable-dialog-title"
                    />
                    {userOwnSection &&
                        <Button
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={() => this.setState({deleteOpen: true})}
                        >
                            Delete
                        </Button>
                    }
                    <DeleteConfirmationDialog
                        open={this.state.deleteOpen}
                        closeHandler={this.handleCloseDelete}
                        actionHandler={() => deleteSection(pk, courseSlug)}
                        itemName={"this section"}
                    />
                </div>
            </Card>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(SectionTitleCard));