export const getUserCourses = (state, username) => {
    return state.courses.courses.filter(c => c.owner.username === username);
};

export const getUserBookmarks = (state, username) => {
    return state.courses.bookmarks.filter(b => b.owner.username === username);
};

export const getCourseBySlug = (state, slug) => {
    const [result] = state.courses.courses.filter(c => c.slug === slug);
    return result;
};

export const getCourseSections = (state, course_slug) => {
    return state.sections.sections.filter(s => s.course.slug === course_slug).sort((a,b) => a.order-b.order);
};

export const getSectionById = (state, pk) => {
    const [result] = state.sections.sections.filter(s => s.pk === pk);
    return result;
};

const TIME_INTERVALS = ['5min', '1day', '2days', '4days', '1wk', '2wk', '1mo', '2mo', '4mo', '8mo', '1.5yr', '3yr'];
const MAX_STREAK = 11;

export const getItemRepetitionTime = (state, pk) => {
    const [item] = state.items.items.filter(item => item.pk === pk);
    if (!item) {
        return null;
    }

    let nextStreakLength = item.streak_length + 1;

    nextStreakLength = MAX_STREAK <= nextStreakLength ? MAX_STREAK : nextStreakLength;

    return TIME_INTERVALS[nextStreakLength];
};