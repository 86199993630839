import { withRouter } from 'react-router';
import React from "react"

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
// with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
// const RouterNavLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

/**
 * Wraps component and pass ref to them
 * @param Wrapped - component to be wrapped
 * @returns {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<any>>}
 */
export const withRouterAndRef = Wrapped => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
        <Wrapped ref={forwardRef} {...otherProps} />
    ));

    const WithRouterAndRef = React.forwardRef((props, ref) => (
        <WithRouter {...props} forwardRef={ref} />
    ));

    const name = Wrapped.displayName || Wrapped.name;
    WithRouterAndRef.displayName = `withRouterAndRef(${name})`;

    return WithRouterAndRef
};
