import React from 'react';
import {Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import UnregisteredButtons from './UnregisteredButtons'
import UserButtons from './UserButtons'
import {connect} from 'react-redux';


const styles = theme => ({
    menuButton: {
        marginRight: "auto",
    },
    logo: {
        height: 36,
        /*[theme.breakpoints.down('xs')]: {
          height: 36,
        },
        [theme.breakpoints.up('sm')]: {
          height: 48,
        },*/
    },
    appBarSpaceDiv: {
        minHeight: theme.sizes.appBarSize,
    }
});

const mapStateToProps = state => {
    return {
        loggedIn: !!state.auth.username
    }
};

class Header extends React.Component {
    render () {
        const { classes, loggedIn } = this.props;
        let buttons;
        if (loggedIn) {
            buttons = <UserButtons/>
        } else {
            buttons = <UnregisteredButtons/>
        }
        return (
            <React.Fragment>
                <AppBar>
                    <Toolbar variant="dense">
                        <Button color="inherit" component={Link} to="/" className={classes.menuButton}>
                            <img src={require('../images/logo.png')} alt="" className={classes.logo}/>
                        </Button>
                        {buttons}
                    </Toolbar>
                </AppBar>
                <div className={classes.appBarSpaceDiv}/>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Header));