import React from "react";
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import UpdateCourse from './UpdateCourse'
import {CLOSE_EDIT_COURSE} from "../reducers/ActionTypes";


const mapStateToProps = (state) => ({
    isOpened: state.courses.editOpened,
    slug: state.courses.updateSlug,
});

const mapDispatchToPros = (dispatch) => ({
    closeEditCourse: () => dispatch({type: CLOSE_EDIT_COURSE}),
});

class UpdateCourseDialog extends React.Component {
    handleCloseNewCourse = () => {
        this.props.closeEditCourse();
    };

    render () {
        const { slug } = this.props;
        return (
            <Dialog
                open={this.props.isOpened}
                onClose={this.handleCloseNewCourse}
                PaperComponent={UpdateCourse}
                PaperProps={{slug}}
                aria-labelledby="draggable-dialog-title"
            >
                <div/>
            </Dialog>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToPros)(UpdateCourseDialog);