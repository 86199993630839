import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {CLOSE_ONBOARDING} from "../reducers/ActionTypes";
import OnboardingStepper from "./OnboardingStepper";

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
};

const mapStateToProps = state => {
    return {
        onboardingOpened: state.auth.onboardingOpened
    }
};

const mapDispatchToProps = dispatch => ({
    closeOnboarding: () => dispatch({type: CLOSE_ONBOARDING})
});

class Onboarding extends React.Component {
    handleClose = () => {
        this.props.closeOnboarding();
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.onboardingOpened}
                    // open={true}
                    onClose={this.handleClose}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Let's get started
                            </Typography>
                            <Button color="inherit" onClick={this.handleClose}>
                                Skip tutorial
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <OnboardingStepper closeOnboarding={this.props.closeOnboarding}/>
                </Dialog>
            </div>
        );
    }
}

Onboarding.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Onboarding));