import HotkeyPlugin from "./HotkeyPlugin";
import RenderMarkPlugin from "./RenderMarkPlugin";
import React from "react";
import {IconActionButton} from "./IconActionButton";
import {FormatBold} from "@material-ui/icons";

const BOLD = 'bold';

const addBoldThunk = (editor) => () => editor.toggleMark(BOLD);

export function BoldButton(props) {
    const {editor} = props;
    const isActive = editor.isMarkActive(BOLD);

    return <IconActionButton
        isActive={isActive}
        onClickAction={addBoldThunk(editor)}
        icon={<FormatBold/>}
        title='Bold (Ctrl+B)'
    />
}

export function BoldPlugin(options) {

    return [
        HotkeyPlugin(
            {
                hotkey: 'mod+b',
                editorThunk: addBoldThunk
            }),

        RenderMarkPlugin(
            {
                type: BOLD,
                render: (attributes, children) => <strong {...attributes}>{children}</strong>
            }),
    ]
}