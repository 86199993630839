import React from "react";
import {connect} from 'react-redux';
import CoursePreview from "./CoursePreview";
import Typography from '@material-ui/core/Typography';
import NewCourseButton from "./NewCourseButton";
import UpdateCourseDialog from "./UpdateCourseDialog";
import {FETCH_BOOKMARKS, FETCH_USER_COURSES} from '../reducers/ActionTypes';
import {getUserBookmarks, getUserCourses} from "../selectors";
import {withStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HomeIcon from '@material-ui/icons/Home';
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {Link} from "react-router-dom";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import AccountCircle from '@material-ui/icons/AccountCircle'
import {stringToAvatarColor} from "../utils";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Badge from "@material-ui/core/Badge";

const styles = theme => ({
    header: {
        margin: '20px'
    },
    accountIcon: {
        margin: '5px',
        [theme.breakpoints.up('md')]: {
            fontSize: '60px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '40px',
        },
    },
    coursesTabs: {
        maxWidth: 800,
        [theme.breakpoints.down('sm')]: {
            margin: '0px auto 0px auto',
        },
        [theme.breakpoints.up('md')]: {
            margin: '20px auto 80px auto',
        },
        flexGrow: 1,
    }
});

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.auth.username,
    userCourses: getUserCourses(state, ownProps.match.params.username),
    userBookmarks: getUserBookmarks(state, ownProps.match.params.username),
    tabAddress: ownProps.location.pathname.split('/').pop(),
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserCourses: (username) => dispatch({type: FETCH_USER_COURSES, username}),
    fetchUserBookmarks: (username) => dispatch({type: FETCH_BOOKMARKS, username})
});


const USER_COURSES_ADDRESS = 'courses';
const BOOKMARKS_ADDRESS = 'bookmarks';

class UserCourses extends React.Component {
    componentDidMount() {
        const username = this.props.match.params.username;
        this.props.fetchUserCourses(username);
        this.props.fetchUserBookmarks(username);
        this.props.fetchUserBookmarks(this.props.currentUser);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const userSwitched = this.props.match.params.username !== prevProps.match.params.username;
        const userSignedIn = this.props.currentUser && !prevProps.currentUser;
        const tabAddressSwitched = this.props.tabAddress !== prevProps.tabAddress;

        if (userSignedIn || userSwitched || tabAddressSwitched) {
            const username = this.props.match.params.username;
            this.props.fetchUserCourses(username);
            this.props.fetchUserBookmarks(username);
            this.props.fetchUserBookmarks(this.props.currentUser);
        }
    }

    render() {
        const {currentUser, userCourses, userBookmarks, classes, tabAddress} = this.props;
        const username = this.props.match.params.username;
        const isOwner = username === currentUser;

        const userCoursesBlock = (
            <div>
                {userCourses.length === 0 &&
                <Card elevation={1} square={true}>
                    <CardHeader subheader={'No courses created...'}/>
                </Card>
                }

                {userCourses.map(course => <CoursePreview key={course.slug} course={course}/>)}
                {isOwner ? <UpdateCourseDialog/> : null}
            </div>
        );

        const bookmarksBlock = (
            <div>
                {userBookmarks.length === 0 &&
                <Card elevation={1} square={true}>
                    <CardHeader subheader={'No bookmarks added...'}/>
                </Card>
                }

                {userBookmarks.map(bookmark => <CoursePreview key={bookmark.course.slug} course={bookmark.course}/>)}
            </div>
        );

        return (
            <div>
                <div className={classes.coursesTabs}>
                    <AppBar position="static" color="default">
                        <Grid container justify="space-evenly" alignItems="center">
                            <Grid item xs={1} sm={1}>
                                <AccountCircle className={classes.accountIcon}
                                               style={{color: stringToAvatarColor(username)}}/>
                            </Grid>
                            <Hidden smUp>
                                <Grid item xs={6}>
                                    <Typography className={classes.header} variant="h6">
                                        {isOwner ? "My courses" : `${username} courses`}
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Hidden xsDown>
                                <Grid item sm={7}>
                                    <Typography className={classes.header} variant="h4">
                                        {isOwner ? "My public courses" : `${username} courses`}
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Grid container justify='center' item xs={5} sm={4}>
                                <Grid item>
                                    {isOwner && tabAddress === USER_COURSES_ADDRESS &&
                                    <NewCourseButton/>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Tabs
                            value={tabAddress}
                            variant="scrollable"
                            scrollButtons="off"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label={isOwner ? "My Courses" : "User Courses"}
                                 value={USER_COURSES_ADDRESS}
                                 component={Link}
                                 to={`/@${username}/courses`}
                                 icon={
                                     <Badge showZero className={classes.margin} badgeContent={userCourses.length}
                                            color="primary">
                                         <HomeIcon/>
                                     </Badge>}/>
                            <Tab label={`Bookmarks`}
                                 value={BOOKMARKS_ADDRESS}
                                 component={Link}
                                 to={`/@${username}/bookmarks`}
                                 icon={
                                     <Badge showZero className={classes.margin} badgeContent={userBookmarks.length}
                                            color="primary">
                                         <BookmarkIcon/>
                                     </Badge>}/>

                        </Tabs>
                    </AppBar>
                    {tabAddress === USER_COURSES_ADDRESS && userCoursesBlock}
                    {tabAddress === BOOKMARKS_ADDRESS && bookmarksBlock}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(UserCourses));