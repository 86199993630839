import {createLogic} from "redux-logic";
import {
    DELETE_SECTION,
    MOVE_SECTION,
    ERROR,
    FETCH_SECTIONS,
    NEW_SECTION,
    REMOTE_SLATE_UPDATED,
    SET_NULL_SLATE_VALUE,
    SET_SECTIONS,
    UPDATE_SECTION_TITLE
} from "../reducers/ActionTypes";
import agent from "../agent";
import {getCourseBySlug} from "../selectors";
import {dispatchNextInChainAndDone} from "./ChainUtils";

const fetchSectionsLogic = createLogic({
    type: FETCH_SECTIONS,
    process({getState, action}, dispatch, done) {

        const currentUser = getState().auth.username;
        const course = getCourseBySlug(getState(), action.course_slug);

        agent.Sections.byCourse(action.course_slug)(action.meta.token)
            .then(data => {
                if (data.length > 0) {
                    dispatch({type: SET_SECTIONS, sections: data});
                } else if (course && course.owner.username === currentUser) {
                    dispatch({type: NEW_SECTION, course_slug: action.course_slug});
                }
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const newSectionLogic = createLogic({
    type: NEW_SECTION,
    process({getState, action}, dispatch, done) {
        agent.Sections.create(action.title || "Untitled section", action.course_slug)(action.meta.token)
            .then(data => {
                dispatch({type: FETCH_SECTIONS, course_slug: action.course_slug});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const updateSectionTitle = createLogic({
    type: UPDATE_SECTION_TITLE,
    process({getState, action}, dispatch, done) {
        agent.Sections.update(action.pk, action.title)(action.meta.token)
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const deleteSection = createLogic({
    type: DELETE_SECTION,
    process({getState, action}, dispatch, done) {
        agent.Sections.del(action.pk)(action.meta.token)
            .then(data => {
                dispatch({type: REMOTE_SLATE_UPDATED});
                dispatch({type: SET_NULL_SLATE_VALUE});
                dispatch({type: FETCH_SECTIONS, course_slug: action.course_slug});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const moveSection = createLogic({
    type: MOVE_SECTION,
    process({getState, action}, dispatch, done) {
        agent.Sections.update(action.pk, null, action.moveto)(action.meta.token)
            .then(data => {
                dispatch({type: REMOTE_SLATE_UPDATED});
                dispatch({type: SET_NULL_SLATE_VALUE});
                dispatch({type: FETCH_SECTIONS, course_slug: action.course_slug});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

export default [fetchSectionsLogic, newSectionLogic, updateSectionTitle, deleteSection, moveSection];