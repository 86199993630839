import React from "react";
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import NewCourse from './NewCourse'
import {CLOSE_NEW_COURSE, OPEN_NEW_COURSE} from "../reducers/ActionTypes";
import {Button} from "@material-ui/core";


const styles = theme => ({
});

const mapStateToProps = (state) => ({
    isOpened: state.courses.newOpened,
});

const mapDispatchToPros = (dispatch) => ({
    openNewCourse: () => dispatch({type: OPEN_NEW_COURSE}),
    closeNewCourse: () => dispatch({type: CLOSE_NEW_COURSE}),
});

class NewCourseButton extends React.Component {

    handleClickNewCourse = () => {
        this.props.openNewCourse();
    };

    handleCloseNewCourse = () => {
        this.props.closeNewCourse();
    };

    render() {
        return (
            <React.Fragment>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleClickNewCourse}
                    size='small'
                >
                    New Course
                </Button>
                <Dialog
                    open={this.props.isOpened}
                    onClose={this.handleCloseNewCourse}
                    PaperComponent={NewCourse}
                    aria-labelledby="draggable-dialog-title"
                >
                    <div/>
                </Dialog>
            </React.Fragment>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToPros)(withStyles(styles)(NewCourseButton));