import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {Link} from 'react-router-dom';


const styles = theme => ({
    footerLayout: {
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: `${theme.spacing(6)}px 0`,
        paddingTop: theme.spacing(8),
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(900 + theme.spacing(6))]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

class Footer extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <footer className={classes.footerLayout}>
                    <Grid container justify="space-evenly">
                        <Grid item xs>
                            <Typography variant="h6" color="textPrimary" gutterBottom align="center"
                                        component={Link} to="/about" style={{textDecoration: "none"}}>
                                About us
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h6" color="textPrimary" gutterBottom align="center"
                                        component={Link} to="/contacts" style={{textDecoration: "none"}}>
                                Contacts
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h6" color="textPrimary" gutterBottom align="center"
                                        component={Link} to="/articles" style={{textDecoration: "none"}}>
                                Articles
                            </Typography>
                        </Grid>
                    </Grid>
                </footer>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Footer);