import {createLogic} from "redux-logic";
import {ERROR, LOAD_SLATE_VALUE, SET_SLATE_VALUE} from "../reducers/ActionTypes";
import agent from "../agent";
import {Value} from "slate";
import {dispatchNextInChainAndDone} from "./ChainUtils";

const fetchSectionValueLogic = createLogic({
    type: LOAD_SLATE_VALUE,
    process({getState, action}, dispatch, done) {
        const {sectionKey, meta} = action;

        agent.Sections.get(sectionKey)(meta.token)
            .then(section => {
                const value = mapNodesToValue(JSON.parse(section.nodes), sectionKey);
                dispatch({type: SET_SLATE_VALUE, value})
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

export const mapNodesToValue = (nodes, sectionKey) => {
    return Value.fromJSON({
        document: {
            nodes: [
                ...nodes
            ],
            data: {
                sectionKey: sectionKey
            }
        }
    });
};

export default [fetchSectionValueLogic]
