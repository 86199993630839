import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from "../styles/dialogStyle";
import {UPDATE_COURSE} from "../reducers/ActionTypes";
import {getCourseBySlug} from "../selectors";


const mapStateToProps = (state, ownProps) => ({
    isUpdating: state.courses.isUpdating,
    errorText: state.courses.errorText,
    currentCourse: getCourseBySlug(state, ownProps.slug),
});

const mapDispatchToProps = (dispatch) => ({
    updateCourse: (title, description) => dispatch({type: UPDATE_COURSE, title, description}),
});

class UpdateCourse extends React.Component {
    constructor(props) {
        super(props);
        const {currentCourse} = props;
        this.state = {
            title: currentCourse.title,
            description: currentCourse.description,
        };
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (!this.props.isUpdating) {
            this.props.updateCourse(this.state.title, this.state.description);
        }
    };

    render () {
        const {classes} = this.props;

        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Enter new title and description
                    </Typography>
                    <form className={classes.form} onSubmit={this.onSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="title">Course name</InputLabel>
                            <Input id="title" name="title" autoComplete="title" autoFocus
                            value={this.state.title} onChange={(e) => this.setState({title: e.target.value})}/>
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="description">Course description</InputLabel>
                            <Input id="description" name="description" autoComplete="description"
                            value={this.state.description} onChange={(e) => this.setState({description: e.target.value})}/>
                        </FormControl>
                        <FormHelperText error={true}>{this.props.errorText}</FormHelperText>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.onSubmit}
                            disabled={this.props.isUpdating}
                        >
                            Save
                        </Button>
                    </form>
                </Paper>
            </main>
        );
    }
}

UpdateCourse.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateCourse));