import HotkeyPlugin from "./HotkeyPlugin";
import React from "react";
import {IconActionButton} from "./IconActionButton";
import {Code} from "@material-ui/icons";
import RenderNodePlugin from "./RenderNodePlugin";
import {withStyles} from "@material-ui/core";


export const CODE = 'code';

const styles = theme => ({
    codeBlock: {
        fontFamily: "monospace",
        padding: 0,
        marginTop: '5px',
        marginBottom: '5px',
        backgroundColor: '#2b262b',
        lineHeight: 1.2,
        fontSize: '14px',
    },
    lineContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
    },
    lineNumber: {
        backgroundColor: '#3c383c',
        color: 'grey',
        borderRight: '1px solid grey',
        display: 'inline-block',
        paddingRight: '0.5rem',
        minWidth: '3rem',
        textAlign: 'right',
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        '-o-user-select': 'none',
        userSelect: 'none',
    },
    lineContent: {
        color: '#FFC66D',
        display: 'inline-block',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
    },
});

const newCode = {
    "object": "block",
    "type": "code",
    "nodes": [
        {
            "object": "block",
            "type": "paragraph",
            "nodes": [
                {
                    "object": "text",
                    "leaves": [
                        {
                            "text": ""
                        }
                    ]
                }
            ]
        }
    ]
};

const addCodeThunk = (editor) => () => {
    editor.insertBlock(newCode)
};

export function CodeButton(props) {
    const {editor} = props;
    const isActive = editor.isBlockActive(CODE);

    return (
        <IconActionButton
            isActive={isActive}
            onClickAction={addCodeThunk(editor)}
            icon={<Code/>}
            title='Code Block (Ctrl+ ` )'
        />
    )
}

const CodeBlock = withStyles(styles)((props) => {
    const {classes, children} = props;
    return (
        <div className={classes.codeBlock}>
            <div className={classes.lineContainer}>
                <span className={classes.lineNumber}> </span>
            </div>
            {children.map((child, idx) => {
                return (
                    <div key={idx} className={classes.lineContainer}>
                        <span className={classes.lineNumber}>{idx}</span>
                        <span className={classes.lineContent}>{child}</span>
                    </div>
                );
            })}
            <div className={classes.lineContainer}>
                <span className={classes.lineNumber}> </span>
            </div>
        </div>
    );
});


export function CodePlugin(options) {

    return [
        HotkeyPlugin(
            {
                hotkey: 'mod+`',
                editorThunk: addCodeThunk
            }),

        RenderNodePlugin(
            {
                type: CODE,
                render: (attributes, children) => (
                    <CodeBlock children={children}/>
                )
            }),
        {
            onKeyDown: (event, editor, next) => {
                const spaces = '  ';
                if (event.key === 'Tab') {
                    event.preventDefault();
                    editor.insertText(spaces);
                    return
                }
                next()
            }
        }
    ]
}
