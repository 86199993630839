import React from "react";
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Editor} from "slate-react";
import schema from './slate/schema'
import {rawEditorPlugins} from './slate/plugins';
import {mapNodesToValue} from "../logic/GetSlateLogic";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularLoadingProgress from "./CircularLoadingProgress";


const styles = theme => ({
    questionCard: {
        maxWidth: 800,
        margin: '0 auto 20px auto'
    },
    button: {
        margin: theme.spacing(1),
    },
    textBlock: {
        margin: '20px auto 0 auto',
        paddingRight: '20px',
        paddingLeft: '20px',
        maxWidth: '750px'
    },
});

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
    repetitions: state.repetitions.repetitions,
    repetitionsCount: state.repetitions.repetitionsCount,
});

class Workout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    setNextValue = () => {
        const {repetitions} = this.props;
        const nextRepetition = !!repetitions ? repetitions[0] : null;
        if (nextRepetition) {
            const questionPk = nextRepetition.item.pk;
            const questionNode = JSON.parse(nextRepetition.item.section.nodes).find((node) => node.data && node.data.pk === questionPk);
            if (questionNode) {
                const value = mapNodesToValue([questionNode]);
                this.setState({value, currentItemPk: questionPk});
            } else {
                this.setState({value: null, currentItemPk: null});
            }
        } else {
            this.setState({value: null, currentItemPk: null});
        }
    };

    isRepetitionInState = () => {
        return this.props.repetitions.some(repetition => repetition.item.pk === this.state.currentItemPk);
    };

    componentDidMount() {
        this.setNextValue();
    }

    componentDidUpdate(prevProps) {
        if (this.props.repetitions !== prevProps.repetitions && this.props.repetitions) {
            if (!this.state.currentItemPk) {
                this.setNextValue();
            } else if (!this.isRepetitionInState()) {
                this.setNextValue();
            }
        }
    }

    render() {
        const {classes, repetitions, repetitionsCount} = this.props;

        if (!repetitions) {
            return <CircularLoadingProgress/>
        }

        const renderQuestion = (
            <Editor
                readOnly={true}
                schema={schema}
                value={this.state.value}
                plugins={rawEditorPlugins}
                classes={classes}
            />
        );
        return (
            <React.Fragment>
                <div className={classes.textBlock}>
                    <Typography variant="h5" align="center" gutterBottom>
                        {repetitionsCount + " questions left to repeat"}
                    </Typography>
                    {repetitionsCount === 0 && (
                        <React.Fragment>
                            <Typography align="center">
                                <CheckCircleIcon color='primary' fontSize="large"/>
                            </Typography>
                            <Typography variant="h6" align="center" color='textSecondary'>
                                You answered all questions for now. Add new questions or wait for the next repetition.
                            </Typography>
                            <Typography variant="h6" align="center" color='textSecondary'>
                                To add a new question to spaced repetitions list just create a new question in your course
                                or answer a question in one of other's courses.
                            </Typography>
                        </React.Fragment>
                    )}
                </div>
                <div className={classes.questionCard}>
                    {this.state.value ? renderQuestion : null}
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Workout));