import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Footer from './Footer';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: 0
    },
    gridContainer: {
        padding: 0,
    },
    itemWithMargin: {
        padding: '30px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 30,
    },
    fullWidthTextBlock: {
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

export default function Articles(props) {
    const direction = 'row';
    const justify = 'center';
    const alignItems = 'center';

    const classes = useStyles(props);

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Grid
                        container
                        className={classes.gridContainer}
                        alignItems={alignItems}
                        direction={direction}
                        justify={justify}
                    >
                        <Grid item className={classes.itemWithMargin} xs={12} md={9}>
                            <div className={classes.fullWidthTextBlock}>
                                <Typography variant="h3" gutterBottom align="center">
                                    Articles
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    Popular articles
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            "How to remember anything forever-ish" (a beautiful interactive
                                            presentation)
                                            <a href="https://ncase.me/remember/"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> ncase.me</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            "Spaced Repetition"
                                            <a href="https://www.gwern.net/Spaced-repetition"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> gwern.net</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            "Boost Your Study Strategy With Retrieval and Distributed Practice"
                                            <a href="https://www.psychologicalscience.org/publications/observer/obsonline/boost-your-study-strategy-with-retrieval-and-distributed-practice.html"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> psychologicalscience.org</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            "Augmenting Long-term Memory"
                                            <a href="http://augmentingcognition.com/ltm.html"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> augmentingcognition.com</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            "Everything I Know: Strategies, Tips, and Tricks for Anki"
                                            <a href="https://senrigan.io/blog/everything-i-know-strategies-tips-and-tricks-for-spaced-repetition-anki/"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> senrigan.io</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            "Memorizing a programming language using spaced repetition software"
                                            <a href="https://sivers.org/srs"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> sivers.org</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            "Why books don’t work"
                                            <a href="https://andymatuschak.org/books/"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> andymatuschak.org</a>
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant="h6" gutterBottom={true}>
                                    Scientific articles
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            Karpicke, Jeffrey D., and Janell R. Blunt. "Retrieval practice produces
                                            more
                                            learning than elaborative studying with concept mapping." Science
                                            331.6018
                                            (2011): 772-775.
                                            <a href="https://science.sciencemag.org/content/331/6018/772"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> Link</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            Spitzer, H. F. (1939). Studies in retention. Journal of Educational
                                            Psychology,
                                            30, 641–657.
                                            <a href="https://www.gwern.net/docs/spacedrepetition/1939-spitzer.pdf"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> Link</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            Melton, A. W. (1970). The situation with respect to the spacing of
                                            repetitions
                                            and memory. Journal of Verbal Learning and Verbal Behavior, 9, 596–606.
                                            <a href="https://pdfs.semanticscholar.org/646e/bddae27895bb5dcdf50b4ed275ea13baaf82.pdf"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> Link</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            Cull, W. L. (2000). Untangling the benefits of multiple study
                                            opportunities and
                                            repeated testing for cued recall. Applied Cognitive Psychology, 14,
                                            215–235.
                                            <a href="https://www.gwern.net/docs/spacedrepetition/2000-cull.pdf"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> Link</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            Pimsleur, Paul (February 1967). "A Memory Schedule". The Modern Language
                                            Journal. Blackwell Publishing. 51 (2): 73–75
                                            <a href="http://lifehacking.pl/wp-content/uploads/2015/09/Artyku%C5%82-Paula-Pimsleura-w-czasopis%CC%81mie-Modern-Language-Journal-1967.pdf"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> Link</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            Dempster, F. N. (1988). "The Spacing Effect: A Case Study in the Failure
                                            to
                                            Apply the Results of Psychological Research". American Psychologist,
                                            43(8),
                                            627-634.
                                            <a href="https://psycnet.apa.org/record/1989-03118-001"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> Link</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom={true}>
                                            “Factors that Influence Skill Decay And Retention: a Quantitative Review
                                            and
                                            Analysis”, Arthur et al 1998
                                            <a href="https://www.gwern.net/docs/spacedrepetition/1998-arthur.pdf"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> Link</a>
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <Footer/>
            </div>
        </React.Fragment>
    );
}