import React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from "../styles/dialogStyle";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";


class DeleteConfirmationDialog extends React.Component {
    render() {
        const {open, itemName, actionHandler, closeHandler} = this.props;
        return (
            <Dialog
                open={open}
                onClose={closeHandler}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{
                    style: {margin: "24px"}
                }}
                maxWidth="xs"
            >
                <DialogTitle id="alert-dialog-title">Confirm delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {itemName}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler} color="default">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        actionHandler();
                        closeHandler()
                    }} color="default" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(DeleteConfirmationDialog);