import React from 'react';
import {PlayCircleOutline} from "@material-ui/icons";
import {IconActionButton} from './IconActionButton';
import Dialog from '@material-ui/core/Dialog';
import NewEmbedPaper from './EmbedNodeDataPaper';
import YouTubeNode from "./YouTubeNode";


export class YouTubeButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {youtubeDialog: false};
    }

    handleClose = () => this.setState({youtubeDialog: false});

    handleSubmitGenerator = (value) => (event) => {
        event.preventDefault();
        const {editor} = this.props;
        const videoId = getVideoIdFromUrl(value);
        if (videoId.length === 11) {
            insertYouTube(editor, videoId);
            this.handleClose();
        }
    };

    render() {
        return (
            <React.Fragment>
                <IconActionButton
                    isActive={false}
                    onClickAction={() => this.setState({youtubeDialog: true})}
                    icon={<PlayCircleOutline/>}
                    title='Add Youtube Video'
                />
                <Dialog
                    open={this.state.youtubeDialog}
                    onClose={this.handleClose}
                    PaperComponent={NewEmbedPaper}
                    PaperProps={{title: "Paste a YouTube link", handleSubmitGenerator: this.handleSubmitGenerator}}
                    aria-labelledby="draggable-dialog-title"
                >
                    <div/>
                </Dialog>
            </React.Fragment>
        );
    }
}

export function insertYouTube(editor, videoId, start, end) {
    editor.insertBlock({
        object: "block",
        type: 'youtube',
        data: {
            src: videoId,
            start: start,
            end: end
        },
    });
}

export function getVideoIdFromUrl(url) {
    const r = url.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/);
    if (r && r.length > 1) {
        return r[1];
    }
    return "";
}

function YouTubePlugin(options) {
    return {
        renderNode(props, editor, next) {
            const {node} = props;

            switch (node.type) {
                case 'youtube':
                    return (
                        <div>
                            <YouTubeNode editor={editor} node={node}/>
                        </div>
                    );
                default:
                    return next()
            }
        },
    };
}

export default YouTubePlugin;