import {createLogic} from "redux-logic";
import {ERROR, LOGOUT} from "../reducers/ActionTypes";
import {dispatchNextInChainAndDone} from "./ChainUtils";

export const UNAUTHORIZED_STATUS = 401;

const isUnauthorized = (error) => {
    return error && error.status === UNAUTHORIZED_STATUS
};

const generalErrorLogic = createLogic({
    type: ERROR,
    process({getState, action}, dispatch, done) {
        if (isUnauthorized(action.error)) {
            dispatch({
                type: LOGOUT
            })
        }
        console.log(action.error);
        dispatchNextInChainAndDone(action, dispatch, done)
    }
});

export default [generalErrorLogic]