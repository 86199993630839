import QueryPlugin from "./QueryPlugin";
import {BoldPlugin} from "./BoldPlugin";
import {ItalicPlugin} from "./ItalicPlugin";
import YouTubePlugin from "./YouTubePlugin";
import ToolbarPlugin from "./ToolbarPlugin";
import LinkPlugin from "./LinkPlugin";
import {HeaderPlugin} from "./HeadersPlugin";
import RenderQuestionPlugin from "./RenderQuestionPlugin";
import {CodePlugin} from "./CodePlugin";
import ImagesPlugin from "./ImagesPlugin";
import QuestionOnPastePlugin from "./QuestionOnPastePlugin";
import {QuestionsPlugin} from "./QuestionsPlugin";
import LaTeXPlugin from "./LaTeXPlugin";

const plugins = [QueryPlugin(), ToolbarPlugin(), LaTeXPlugin(), BoldPlugin(), ItalicPlugin(), CodePlugin(), QuestionsPlugin(),
    RenderQuestionPlugin(), LinkPlugin(), HeaderPlugin(), ImagesPlugin(), YouTubePlugin(),
    QuestionOnPastePlugin()];

export const rawEditorPlugins = [QueryPlugin(), LaTeXPlugin(), BoldPlugin(), ItalicPlugin(), CodePlugin(),
    RenderQuestionPlugin(), LinkPlugin(), HeaderPlugin(), ImagesPlugin(), YouTubePlugin(),
    QuestionOnPastePlugin()];

export default plugins;