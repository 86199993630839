import {createLogic} from "redux-logic";
import {
    BOOKMARK_COURSE,
    CLOSE_EDIT_COURSE,
    CLOSE_NEW_COURSE,
    CONCATENATE_COURSES_PAGE,
    DELETE_BOOKMARK,
    DELETE_COURSE,
    ERROR,
    FETCH_BOOKMARKS,
    FETCH_COURSE_BY_SLUG,
    FETCH_COURSES_PAGE,
    FETCH_USER_COURSES,
    FINISH_NEW_COURSE,
    FINISH_UPDATE_COURSE,
    NEW_COURSE,
    NEW_SECTION,
    REMOVE_BOOKMARK_FROM_STATE,
    SET_COURSES,
    SET_COURSES_ERROR,
    START_NEW_COURSE,
    START_UPDATE_COURSE,
    UPDATE_COURSE,
    UPDATE_USER_BOOKMARKS,
} from "../reducers/ActionTypes";
import agent from "../agent";
import {dispatchNextInChainAndDone} from "./ChainUtils";
import {PAGE_SIZE} from "../reducers/courses";

const fetchUserCoursesLogic = createLogic({
    type: FETCH_USER_COURSES,
    process({getState, action}, dispatch, done) {
        agent.Courses.byUser(action.username)(action.meta.token)
            .then(data => {
                dispatch({type: SET_COURSES, courses: data});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const fetchCoursesPageLogic = createLogic({
    type: FETCH_COURSES_PAGE,
    process({getState, action}, dispatch, done) {
        agent.Courses.getList(action.featured, action.page, PAGE_SIZE)(action.meta.token)
            .then(data => {
                if (action.page === 1) {
                    dispatch({type: SET_COURSES, courses: data});
                } else if (action.page > 1) {
                    dispatch({type: CONCATENATE_COURSES_PAGE, courses: data});
                }
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const fetchBookmarksLogic = createLogic({
    type: FETCH_BOOKMARKS,
    process({getState, action}, dispatch, done) {
        agent.Bookmarks.byUser(action.username)(action.meta.token)
            .then(data => {
                dispatch({type: UPDATE_USER_BOOKMARKS, bookmarks: data, username: action.username});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const bookmarkCourseLogic = createLogic({
    type: BOOKMARK_COURSE,
    process({getState, action}, dispatch, done) {
        agent.Bookmarks.create(action.slug)(action.meta.token)
            .then(data => {
                dispatch({type: UPDATE_USER_BOOKMARKS, bookmarks: [data]});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const removeBookmarkLogic = createLogic({
    type: DELETE_BOOKMARK,
    process({getState, action}, dispatch, done) {
        agent.Bookmarks.del(action.bookmarkPk)(action.meta.token)
            .then(data => {
                dispatch({type: REMOVE_BOOKMARK_FROM_STATE, bookmarkPk: action.bookmarkPk});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const fetchCourseBySlugLogic = createLogic({
    type: FETCH_COURSE_BY_SLUG,
    process({getState, action}, dispatch, done) {
        agent.Courses.get(action.slug)(action.meta.token)
            .then(data => {
                dispatch({type: SET_COURSES, courses: [data]});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const newCourseLogic = createLogic({
    type: NEW_COURSE,
    process({getState, action}, dispatch, done) {
        dispatch({type: START_NEW_COURSE});
        agent.Courses.create(action.title, action.description)(action.meta.token)
            .then(course => {
                dispatch({type: FETCH_USER_COURSES, username: course.owner.username});
                dispatch({type: NEW_SECTION, course_slug: course.slug});
                dispatch({type: CLOSE_NEW_COURSE});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
                dispatch({type: SET_COURSES_ERROR, errorText: "Something went wrong"})
            })
            .then(() => dispatch({type:FINISH_NEW_COURSE}))
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const updateCourseLogic = createLogic({
    type: UPDATE_COURSE,
    process({getState, action}, dispatch, done) {
        dispatch({type: START_UPDATE_COURSE});
        agent.Courses.update(getState().courses.updateSlug, action.title, action.description)(action.meta.token)
            .then(data => {
                dispatch({type: FETCH_USER_COURSES, username: data.owner.username});
                dispatch({type: CLOSE_EDIT_COURSE});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
                dispatch({type: SET_COURSES_ERROR, errorText: "Something went wrong"})
            })
            .then(() => dispatch({type:FINISH_UPDATE_COURSE}))
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const deleteCourseLogic = createLogic({
    type: DELETE_COURSE,
    process({getState, action}, dispatch, done) {
        agent.Courses.del(action.slug)(action.meta.token)
            .then(data => {
                dispatch({type: FETCH_USER_COURSES, username: action.username});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

export default [fetchUserCoursesLogic, fetchCoursesPageLogic, fetchBookmarksLogic, bookmarkCourseLogic, removeBookmarkLogic,
    fetchCourseBySlugLogic, newCourseLogic, updateCourseLogic, deleteCourseLogic];