import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import {withStyles} from '@material-ui/core/styles';
import Fab from "@material-ui/core/Fab";
import CourseDrawer from "./CourseDrawer";
import SectionBody from "./SectionBody";
import {getCourseBySlug, getCourseSections} from "../selectors";
import {FETCH_COURSE_BY_SLUG, FETCH_SECTIONS} from "../reducers/ActionTypes";
import CircularLoadingProgress from "./CircularLoadingProgress";
import { setPreviewSectionUrl, setEditSectionUrl } from "../utils";

const drawerWidth = 340;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
            zIndex: theme.zIndex.appBar - 1,
        },
    },
    sectionsDrawerButton: {
        marginRight: 20,
        top: 'auto',
        left: 20,
        bottom: 20,
        right: 'auto',
        position: 'fixed',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        zIndex: theme.zIndex.appBar + 1,

    },
    appBarSpaceDiv: {
        minHeight: theme.sizes.appBarSize,
    },
    drawerPaper: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
        },
        [theme.breakpoints.only('xs')]: {
            width: 220,
        },
    },
    contentToolbar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,

        },
        width: '100%',
        position: 'fixed',
        top: 0
    }
});

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.auth.username,
    course: getCourseBySlug(state, ownProps.match.params.course_slug),
    courseSlug: ownProps.match.params.course_slug,
    sections: getCourseSections(state, ownProps.match.params.course_slug),
    currentSectionPk: Number(ownProps.match.params.section_pk),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCourse: (slug) => dispatch({type: FETCH_COURSE_BY_SLUG, slug}),
    fetchSections: (course_slug) => dispatch({type: FETCH_SECTIONS, course_slug}),
});

class ResponsiveDrawer extends React.Component {
    state = {
        mobileOpen: true,
    };

    componentDidMount() {
        const {courseSlug} = this.props;

        this.props.fetchCourse(courseSlug);
        this.props.fetchSections(courseSlug);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentSectionPk, sections, courseSlug, edit} = this.props;
        const sectionsLoaded = sections.length > 0;

        if (sectionsLoaded) {
            const sectionsKeys = sections.map(s => s.pk);
            const currentSectionKeyValid = sectionsKeys.includes(currentSectionPk);

            if (!currentSectionKeyValid) {
                const FIRST_INDEX = 0;
                const firstSection = sections[FIRST_INDEX];

                if(edit) {
                    setEditSectionUrl(firstSection.pk, this.props);
                } else {
                    setPreviewSectionUrl(firstSection.pk, this.props);
                }
            }
        }
        if (courseSlug !== prevProps.courseSlug) {
            this.props.fetchCourse(courseSlug);
            this.props.fetchSections(courseSlug);
        }
    }


    handleDrawerToggle = () => {
        this.setState(state => ({mobileOpen: !state.mobileOpen}));
    };

    render() {
        const {match, edit, classes, theme, course, sections, currentUser, history} = this.props;

        if (!course) {
            return <CircularLoadingProgress/>
        }

        const ownedByUser = course.owner.username === currentUser;

        return (
            <div className={classes.root}>
                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden mdUp implementation="js">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <CourseDrawer
                                mobile={true}
                                classes={classes}
                                course={course}
                                sections={sections}
                                ownedByUser={ownedByUser}
                                handleDrawerToggle={this.handleDrawerToggle}
                                match={match}
                                history={history}
                                edit={edit}
                            />
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="js">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            <CourseDrawer
                                mobile={false}
                                classes={classes}
                                course={course}
                                sections={sections}
                                ownedByUser={ownedByUser}
                                match={match}
                                history={history}
                                edit={edit}
                            />
                        </Drawer>
                    </Hidden>
                </nav>
                <Fab size="small" color="inherit" aria-label="Open drawer" className={classes.sectionsDrawerButton}
                     onClick={this.handleDrawerToggle}>
                    <MenuIcon/>
                </Fab>
                <SectionBody match={match} sections={sections} ownedByUser={ownedByUser} edit={edit} embed={false}
                history={history}/>
            </div>
        );
    }
}

ResponsiveDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(ResponsiveDrawer));