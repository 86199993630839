import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export function IconActionButton(props) {
    const {isActive, onClickAction, icon, title} = props;

    const onClick = (event) => {
        event.preventDefault();
        onClickAction()
    };

    return (
        <Tooltip title={title}>
            <IconButton
                size="small"
                color={isActive ? "primary" : "default"}
                onMouseDown={onClick}
                style={{padding: 4}}
            >
                {icon}
            </IconButton>
        </Tooltip>
    )
}