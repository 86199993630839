import {combineReducers} from 'redux';
import auth from './reducers/auth';
import courses from './reducers/courses';
import sections from './reducers/sections';
import repetitions from './reducers/repetitions';
import items from './reducers/items';

export default combineReducers({
    auth,
    courses,
    sections,
    repetitions,
    items,
});