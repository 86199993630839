import React from "react";
import Button from '@material-ui/core/Button';
import {Document} from 'slate'
import HotkeyPlugin from "./HotkeyPlugin";
import {CODE} from "./CodePlugin";
import Snackbar from "@material-ui/core/Snackbar";
import {SnackbarContent, withStyles} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import green from "@material-ui/core/es/colors/green";
import clsx from 'clsx';
import Tooltip from "@material-ui/core/Tooltip";

export const QUESTION_BLOCK = "questionBlock";

const EMPTY_PARAGRAPH = {
    "object": "block",
    "type": "paragraph",
    "data": {},
    "nodes": [
        {
            "object": "text",
            "leaves": [
                {
                    "object": "leaf",
                    "text": "",
                    "marks": []
                }
            ]
        }
    ]
};

export const QUESTION_NODE = {
    "object": "block",
    "type": "question",
    "data": {},
    "nodes": [EMPTY_PARAGRAPH]
};

export const ANSWER_NODE = {
    "object": "block",
    "type": "answer",
    "data": {},
    "nodes": [EMPTY_PARAGRAPH]
};

const newQuestion = {
    "object": "block",
    "type": QUESTION_BLOCK,
    "nodes": [
        QUESTION_NODE,
        ANSWER_NODE
    ]
};

const questionFragment = Document.fromJSON({
    nodes: [
        newQuestion,
    ]
});

const addQuestionThunk = (editor) => () => {
    const currentBlockKey = editor.value.blocks.first().key;
    const topAncestor = editor.value.document.getAncestors(currentBlockKey).get(1);
    if (topAncestor && (topAncestor.type === QUESTION_BLOCK || topAncestor.type === CODE)) {
        const nodeAfterQuestion = editor.value.document.getNextSibling(topAncestor.key);
        editor.moveTo(nodeAfterQuestion.key)
    }
    editor.insertFragment(questionFragment);
};

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

class RawNewQuestionButton extends React.Component {
    state = {
        snackbarOpen: false
    };

    handleSnackbarClose = () => this.setState({snackbarOpen: false});
    handleSnackbarOpen = () => this.setState({snackbarOpen: true});

    render() {
        const {editor, classes} = this.props;

        const addedToRepeatMessage = (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={this.state.snackbarOpen}
                autoHideDuration={2000}
                onClose={this.handleSnackbarClose}
            >
                <SnackbarContent
                    className={classes.success}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.message}>
                                <CheckCircleIcon className={clsx(classes.icon, classes.iconVariant)}/>
                                Question added to repetitions list!
                            </span>
                    }
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarClose}>
                            <CloseIcon className={classes.icon}/>
                        </IconButton>
                    }
                />
            </Snackbar>
        );

        return (
            <React.Fragment>
                <Tooltip title="(Ctrl+Enter)">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            addQuestionThunk(editor)();
                            this.handleSnackbarOpen();
                        }}
                    >
                        Add new question
                    </Button>
                </Tooltip>
                {addedToRepeatMessage}
            </React.Fragment>
        );
    }
}

export const NewQuestionButton = withStyles(styles)(RawNewQuestionButton);

export function QuestionsPlugin(options) {
    return [
        HotkeyPlugin(
            {
                hotkey: 'mod+enter',
                editorThunk: addQuestionThunk
            }),
    ]
}
