export function chainActionsThatUseLogic(actionsArray) {
    const actions = actionsArray.reverse();

    const reducer = (nextActionsChain, earlierAction) => {

        return {
            ...earlierAction,
            meta: {
                ...earlierAction.meta,
                next: nextActionsChain
            }
        }
    };

    return actions.reduce(reducer)
}

export function dispatchNextInChainAndDone(action, dispatch, done) {
    if (action.meta && action.meta.next) {
        dispatch(action.meta.next);
    }
    done()
}
