import React from "react";
import {connect} from 'react-redux';
import CoursePreview from "./CoursePreview";
import Typography from '@material-ui/core/Typography';
import {FETCH_COURSES_PAGE} from '../reducers/ActionTypes';
import {withStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FeaturedIcon from '@material-ui/icons/Stars';
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {Link} from "react-router-dom";
import LibraryBooksIcon from '@material-ui/icons/CollectionsBookmark';
import Grid from "@material-ui/core/Grid";
import InfiniteScroll from 'react-infinite-scroller'

const styles = theme => ({
    header: {
        margin: '20px'
    },
    accountIcon: {
        margin: '5px',
        [theme.breakpoints.up('md')]: {
            fontSize: '60px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '40px',
        },
    },
    coursesTabs: {
        maxWidth: 800,
        [theme.breakpoints.down('sm')]: {
            margin: '0px auto 0px auto',
        },
        [theme.breakpoints.up('md')]: {
            margin: '20px auto 80px auto',
        },
        flexGrow: 1,
    }
});

const mapStateToProps = (state, ownProps) => ({
    allCourses: state.courses.courses,
    haveMorePages: state.courses.haveMorePages,
    tabAddress: ownProps.location.pathname.split('/').pop(),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCourses: (featured, page) => dispatch({type: FETCH_COURSES_PAGE, featured, page}),
});


const ALL_COURSES_ADDRESS = 'courses';
const FEATURED_ADDRESS = 'featured';

class UserCourses extends React.Component {
    componentDidMount() {
        this.handleFetchCourses(1);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const tabAddressSwitched = this.props.tabAddress !== prevProps.tabAddress;

        if (tabAddressSwitched) {
            this.handleFetchCourses(1)
        }
    }

    handleFetchCourses = (page) => {
        const {tabAddress} = this.props;

        if (tabAddress === ALL_COURSES_ADDRESS) {
            this.props.fetchCourses(false, page)

        } else if (tabAddress === FEATURED_ADDRESS) {
            this.props.fetchCourses(true, page)

        }
    };

    render() {
        const {tabAddress, allCourses, haveMorePages, classes} = this.props;

        const loader = (
            <Card elevation={1} square={true}>
                <CardHeader subheader={'Loading courses...'}/>
            </Card>
        );

        const allCoursesScroll = (
            <InfiniteScroll
                initialLoad={true}
                pageStart={0}
                loadMore={this.handleFetchCourses}
                hasMore={haveMorePages}
                loader={loader}
            >
                {allCourses.map(course => <CoursePreview key={course.slug} course={course}/>)}
            </InfiniteScroll>
        );

        const featuredScroll = (
            <InfiniteScroll
                initialLoad={true}
                pageStart={0}
                loadMore={this.handleFetchCourses}
                hasMore={haveMorePages}
                loader={loader}
            >
                {allCourses.map(course => <CoursePreview key={course.slug} course={course}/>)}
            </InfiniteScroll>
        );

        return (
            <div>
                <div className={classes.coursesTabs}>
                    <AppBar position="static" color="default">
                        <Grid container justify="flex-start" alignItems="center">
                            <Typography className={classes.header} variant="h4">
                                Explore Courses with spaced repetitions!
                            </Typography>
                        </Grid>
                        <Tabs
                            value={tabAddress}
                            variant="scrollable"
                            scrollButtons="off"
                            indicatorColor="primary"
                            textColor="primary"
                        >

                            <Tab label="Featured"
                                 value={FEATURED_ADDRESS}
                                 component={Link}
                                 to={`/catalog/${FEATURED_ADDRESS}`}
                                 icon={<FeaturedIcon/>}
                            />
                            <Tab label="All Courses"
                                 value={ALL_COURSES_ADDRESS}
                                 component={Link}
                                 to={`/catalog/${ALL_COURSES_ADDRESS}`}
                                 icon={<LibraryBooksIcon/>}
                            />

                        </Tabs>
                    </AppBar>
                    {tabAddress === FEATURED_ADDRESS && featuredScroll}
                    {tabAddress === ALL_COURSES_ADDRESS && allCoursesScroll}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(UserCourses));