import React from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import withWidth from '@material-ui/core/withWidth';
import {NavLink} from 'react-router-dom';
import {FETCH_REPETITIONS, LOGOUT} from "../reducers/ActionTypes";
import Hidden from '@material-ui/core/Hidden';
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {withRouterAndRef} from "./utils";

const FAQ_LINK_ENV = process.env.REACT_APP_FAQ_LINK;
const FAQ_LINK = !!FAQ_LINK_ENV ? FAQ_LINK_ENV : "/onboarding";

const RouterNavLink = withRouterAndRef(NavLink);

const mapStateToProps = (state) => ({
    numberOfRepetitions: state.repetitions.repetitionsCount,
    currentUser: state.auth.username,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch({type: LOGOUT}),
    fetchRepetitions: () => dispatch({type: FETCH_REPETITIONS}),
});


class UserButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            timer: null,
        };
    }

    componentDidMount () {
        this.props.fetchRepetitions();
        let timer = setInterval(this.props.fetchRepetitions, 300000);
        this.setState({timer});
    }

    componentWillUnmount () {
        clearInterval(this.state.timer);
    }

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLogout = () => {
        this.props.logout();
        this.handleMenuClose();
    };

    render () {
        const anchorEl = this.state.anchorEl;
        const isMenuOpen = Boolean(anchorEl);
        const button_size = "small";

        const {currentUser} = this.props;

        const mdUpMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem component={RouterNavLink} to="/contacts">Contact us</MenuItem>
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
        );

        const smDownMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem component={RouterNavLink} to={`/@${currentUser}/courses`}>My courses</MenuItem>
                <MenuItem component={RouterNavLink} to="/workout">
                    <ListItemText primary="Repeat"/>
                    <ListItemAvatar>
                        <Avatar style={{
                            margin: '7px',
                            width: 32,
                            height: 32,
                            fontSize: '13px',
                            backgroundColor: '#f50057'
                        }}>
                            {this.props.numberOfRepetitions}
                        </Avatar>
                    </ListItemAvatar>
                </MenuItem>
                <MenuItem component={RouterNavLink} to={`/catalog/featured`}>Explore</MenuItem>
                <MenuItem component={RouterNavLink} to={FAQ_LINK}>FAQ</MenuItem>
                <MenuItem component={RouterNavLink} to="/contacts">Contact us</MenuItem>
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
        );

        return (
            <div>
                <Hidden smDown>
                    <Button size={button_size} color="inherit" component={RouterNavLink} to={FAQ_LINK}>
                        FAQ
                    </Button>
                    <Button size={button_size} color="inherit" component={RouterNavLink} to={`/catalog/featured`}>
                        Explore
                    </Button>
                    <Button size={button_size} color="inherit" component={RouterNavLink} to={`/@${currentUser}/courses`}>
                        My courses
                    </Button>
                    <Badge badgeContent={this.props.numberOfRepetitions} color="secondary" style={{marginRight: 20}}>
                        <Button size={button_size} color="inherit" component={RouterNavLink} to="/workout">
                            Repeat
                        </Button>
                    </Badge>
                    <IconButton
                        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    {mdUpMenu}
                </Hidden>
                <Hidden mdUp>
                    <IconButton
                        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleProfileMenuOpen}
                        color="inherit"
                    >
                        <Badge invisible={!this.props.numberOfRepetitions} variant="dot" color="secondary">
                            <AccountCircle/>
                        </Badge>
                    </IconButton>
                    {smDownMenu}
                </Hidden>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(UserButtons));