import {createLogic} from "redux-logic";
import {
    CLOSE_LOGIN,
    CLOSE_REGISTER,
    ERROR,
    FINISH_SIGN,
    INITIALIZE_USER,
    OPEN_ONBOARDING,
    SET_AUTH_ERROR,
    SET_TOKEN_AND_USERNAME,
    SET_USERNAME,
    SIGN_IN,
    SIGN_UP
} from "../reducers/ActionTypes";
import agent from "../agent";
import {dispatchNextInChainAndDone} from "./ChainUtils";
import LogRocket from "logrocket";

/*
Example error messages:
USERNAME_ALREADY_EXIST = "A user with that username already exists.";
EMAIL_ALREADY_EXIST = "A user is already registered with this e-mail address.";
INVALID_EMAIL_FORMAT = "Enter a valid email address.";
*/

const WRONG_CREDENTIALS_ERROR = "Unable to log in with provided credentials.";
const WRONG_CREDENTIALS_ERROR_TEXT = "Wrong username or password";


const OTHER_ERROR = "something went wrong...";

const signInLogic = createLogic({
    type: SIGN_IN,
    process({getState, action}, dispatch, done) {
        const {username, password, meta} = action;

        agent.Auth.login(username, password)(meta.token)
            .then(data => {
                dispatch({type: SET_TOKEN_AND_USERNAME, token: data.token, username: data.user.username});
                dispatch({type: CLOSE_LOGIN});
            })
            .catch(error => {
                let errorText = OTHER_ERROR;

                if (error.response && error.response.body) {
                    const non_field_errors = error.response.body.non_field_errors;

                    if (non_field_errors) {
                        errorText = non_field_errors[0];
                    }
                }

                if (errorText === WRONG_CREDENTIALS_ERROR) {
                    errorText = WRONG_CREDENTIALS_ERROR_TEXT
                }

                dispatch({type: SET_AUTH_ERROR, errorText: errorText});
            })
            .then(() => dispatch({type: FINISH_SIGN}))
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const signUpLogic = createLogic({
    type: SIGN_UP,
    process({getState, action}, dispatch, done) {
        const {username, email, password, meta} = action;

        agent.Auth.register(username, email, password)(meta.token)
            .then(data => {
                dispatch({type: SET_TOKEN_AND_USERNAME, token: data.token, username: data.user.username});
                dispatch({type: CLOSE_REGISTER});
                dispatch({type: OPEN_ONBOARDING});
            })
            .catch(error => {
                let errorText = OTHER_ERROR;

                if (error.response.body) {
                    const {email, username, password1} = error.response.body;

                    if (email) {
                        errorText = email[0]
                    }

                    if (username) {
                        errorText = username[0]
                    }

                    if (password1) {
                        errorText = password1[0]
                    }
                }

                dispatch({type: SET_AUTH_ERROR, errorText: errorText});

                console.log(JSON.stringify(error));
            })
            .then(() => dispatch({type: FINISH_SIGN}))
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const initializeUserLogic = createLogic({
    type: INITIALIZE_USER,

    validate({getState, action}, allow, reject) {
        if (action.meta.token) {
            allow(action);
        } else {
            reject();
        }
    },

    process({getState, action}, dispatch, done) {
        agent.Auth.current()(action.meta.token)
            .then(data => {
                dispatch({type: SET_USERNAME, username: data.username});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatch({type: FINISH_SIGN}))
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const setLogRocketUsernameLogic = createLogic({
    type: SET_USERNAME,

    process({action}) {
        const {username} = action;
        LogRocket.identify(username);
    }
});

export default [signInLogic, signUpLogic, initializeUserLogic, setLogRocketUsernameLogic]