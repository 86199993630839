import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from "../../styles/dialogStyle";

class EmbedNodeDataPaper extends React.Component {
    constructor (props) {
        super(props);
        if (props.previousValue) {
            this.state = {value: props.previousValue}
        } else {
            this.state = {value: ""}
        }
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
    };

    handleSubmit = (event) => {
        const {handleSubmitGenerator} = this.props;
        handleSubmitGenerator(this.state.value)(event);
    };

    render() {
        const {classes, title, inputLabel, previousValue} = this.props;

        const previousValueExists = previousValue !== undefined;

        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        {title}
                    </Typography>
                    <form className={classes.form} onSubmit={this.handleSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="input">{inputLabel}</InputLabel>
                            <Input id="input" name="input" autoFocus
                                   value={this.state.value} onChange={this.handleChange}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleSubmit}
                        >
                            {previousValueExists ? "Save" : "Add"}
                        </Button>
                        {previousValueExists && <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            onClick={this.props.handleDelete}
                        >
                            Delete
                        </Button>}
                    </form>
                </Paper>
            </main>
        );
    }
}

EmbedNodeDataPaper.propTypes = {
    classes: PropTypes.object.isRequired,
};

EmbedNodeDataPaper.defaultProps = {
    inputLabel: "URL",
};

export default withStyles(styles)(EmbedNodeDataPaper);