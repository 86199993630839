import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Footer from './Footer';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: 0
    },
    gridContainer: {
        padding: 0,
    },
    itemWithMargin: {
        padding: '30px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 30,
    },
    fullWidthTextBlock: {
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

export default function About(props) {
    const direction = 'row';
    const justify = 'center';
    const alignItems = 'center';

    const classes = useStyles(props);

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Grid
                        container
                        className={classes.gridContainer}
                        alignItems={alignItems}
                        direction={direction}
                        justify={justify}
                    >
                        <Grid item className={classes.itemWithMargin} xs={12} md={9}>
                            <div className={classes.fullWidthTextBlock}>
                                <Typography variant="h3" gutterBottom align="center">
                                    About us
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    The dream
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    We started with a dream of a world where all learning is adaptive. Where the
                                    book is replaced by adaptive algorithms that find the best way for you to learn.
                                    Where every new bit of knowledge is served to you exactly at the right moment. A
                                    world where those learning algorithms are created through the collective effort
                                    of the best minds on the topic. Seems utopian. But is it?
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    The idea
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    So we thought about how to move closer to our dream world. In this world you
                                    definitely wouldn't forget all the things you've learned through the years. All
                                    the things you learned years ago would still be with you.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Actually, for almost a century we know how to achieve this. It's done with the
                                    help of a simple adaptive schedule of repetitions. But despite the development
                                    of new technologies and new scientific discoveries, education is fundamentally
                                    the same it was at the beginning of the 20th century. Integration of new
                                    techniques into educational process proved to be difficult.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    So how can we use modern technologies to solve this? On one hand, we know that
                                    many people find online courses useful. You read or watch videos, you take
                                    quizzes. We also know that many people find making study notes useful. On the other
                                    hand, we see specialized spaced repetitions software. It works, but most people
                                    find it hard to use.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    We are convinced that repetitions aren't hard per se. After all people have no
                                    problem taking quizzes. That's why we decided to integrate spaced repetitions
                                    into the proven mediums of online courses and note-taking apps. Surprisingly, it
                                    all converges into one medium. Rich content interlaced with test questions ready
                                    for automatic repetitions. And that's exactly what LearnItFast is doing.
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    Openness
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    We value brilliant individuals. A community of brilliant individuals is even
                                    better. That's why we made editing courses available to all users. And all the
                                    courses are visible to others by default. In the future we plan to add
                                    functionality for collaborative course creation. We would like to see people
                                    with the most knowledge on the topic working collaboratively to share their
                                    knowledge.
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    What you can achieve
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    When you've learned something new the story doesn't end there. The algorithm can
                                    maintain your knowledge for many years.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Just imagine learning many different things for many years. And it all stays
                                    with you. What will you be capable of? Remember, many groundbreaking discoveries
                                    were made by people who knew well more than one field.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Also it's hard to invest time and money in education if you aren't sure you'll
                                    need it in the near future. But if you know that you won't forget it it's a
                                    totally different story. Everything can become unexpectedly useful down the road.
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    Founders
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    We are two nuclear physicists, Arkadiy Kossakovsky (
                                    <a href="https://www.linkedin.com/in/arkadiy-kossakovsky-455174174"
                                       target="_blank" rel="noopener noreferrer"
                                       style={{textDecoration: "none"}}>LinkedIn</a>) and Ihor Litvinov
                                    (
                                    <a href="https://www.linkedin.com/in/ihorlitvinov"
                                       target="_blank" rel="noopener noreferrer"
                                       style={{textDecoration: "none"}}>LinkedIn</a>). We both love to learn new
                                    things. But we also share a belief that the world can be a much better place. A
                                    world in which things like radical life extension, poverty eradication, world
                                    peace are possible. And that it can be achieved through reason, science and
                                    technology.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    The story on this page describes why we were convinced that LearnItFast has to
                                    be created. And having created it we are determined to move towards the dream of
                                    adaptive learning.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Join our journey and build the future with us!
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <Footer/>
            </div>
        </React.Fragment>
    );
}