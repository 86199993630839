import React from 'react';
import {connect} from 'react-redux';
import Header from './Header';
import Home from './Home';
import {withStyles} from '@material-ui/core/styles';
import {Route, Switch} from 'react-router-dom';
import UserCourses from "./UserCourses";
import CoursePage from "./CoursePage";
import Workout from './Workout'
import {INITIALIZE_USER} from "../reducers/ActionTypes";
import Demo from "./Demo";
import {Redirect} from "react-router";
import Catalog from "./Catalog";
import Articles from "./Articles";
import Contacts from "./Contacts";
import About from "./About";
import Onboarding from "./Onboarding";
import ShowOnboarding from "./ShowOnboarding";
import SectionEmbed from "./SectionEmbed";
import ShowLogin from "./ShowLogin";

const styles = theme => ({});

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => ({
    initializeUser: () => dispatch({type: INITIALIZE_USER})
});

class App extends React.Component {
    componentDidMount () {
        this.props.initializeUser();
    }

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact path="/embed/:course_slug/:section_pk?" component={SectionEmbed}/>
                    <Route path="/" component={Header}/>
                </Switch>
                <Onboarding/>
                <Switch>
                    <Redirect exact from='/@:username' to="/@:username/courses"/>
                    <Route exact path="/onboarding" component={ShowOnboarding}/>
                    <Route exact path="/login" component={ShowLogin}/>
                    <Route exact path="/" component={Home}/>
                    {/*<Route exact path="/tutorial" component={Onboarding}/>*/}
                    <Route exact path="/articles" component={Articles}/>
                    <Route exact path="/contacts" component={Contacts}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/@:username/:course_slug/edit/:section_pk?"
                           render={props => (<CoursePage {...props} edit={true}/>)}/>
                    <Route exact path="/@:username/courses" component={UserCourses}/>
                    <Route exact path="/@:username/bookmarks" component={UserCourses}/>
                    <Route exact path="/@:username/:course_slug/:section_pk?"
                           render={props => (<CoursePage {...props} edit={false}/>)}/>
                    {/*<Route path="/@:username" render={UserCourses}/>*/}
                    {/*<Route path="/@:username/:course_slug/:lesson_slug" component={Editor}/>*/}
                    {/*<Route path="/@:username" component={Profile}/>*/}
                    <Route exact path="/workout" component={Workout}/>
                    <Route exact path="/start-demo-mode-for-y-combinator" component={Demo}/>
                    <Route exact path="/catalog/courses" component={Catalog}/>
                    <Route exact path="/catalog/featured" component={Catalog}/>
                </Switch>
            </React.Fragment>
        );
    }
}

// App.contextTypes = {
//   router: PropTypes.object.isRequired
// };

const StyledApp = withStyles(styles, {withTheme: true})(App);

export default connect(mapStateToProps, mapDispatchToProps)(StyledApp);
