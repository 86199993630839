import React from "react";
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core";
import {Editor} from "slate-react";
import schema from './slate/schema';
import plugins from './slate/plugins';
import {getSectionById} from "../selectors";
import {
    LOAD_SLATE_VALUE,
    SET_NULL_SLATE_VALUE,
    UPDATE_REMOTE_SLATE,
    UPDATE_SECTION_VALUE
} from "../reducers/ActionTypes";
import Typography from "@material-ui/core/Typography";
import SectionTitleCard from "./SectionTitleCard";
import SectionActionsCard from "./SectionActionsCard";
import {chainActionsThatUseLogic} from "../logic/ChainUtils";
import AndroidInstabilityAlert from "./AndroidInstabilityAlert";

const styles = theme => ({
    content: {
        flexGrow: 1,
    },
    loadingMessage: {
        margin: '20px'
    }
});

const mapStateToProps = (state, ownProps) => ({
    section: getSectionById(state, Number(ownProps.match.params.section_pk)),
    value: state.sections.slateValue,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateSectionValue: (value) => dispatch({type: UPDATE_SECTION_VALUE, value}),
    updateRemoteSlate: () => dispatch({type: UPDATE_REMOTE_SLATE}),
    fetchSlateValue: (sectionKey) => dispatch({type: LOAD_SLATE_VALUE, sectionKey}),
    updateAndSetNullValue: () => {
        dispatch(chainActionsThatUseLogic([{type: UPDATE_REMOTE_SLATE}, {type: SET_NULL_SLATE_VALUE},]))
    },
    setNullValue: () => dispatch({type: SET_NULL_SLATE_VALUE})
});

class CopyModeEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value}
    }

    onChange = ({value}) => {
        if (this.state.value.document === value.document) {
            this.setState({value});
        }
    };

    render() {
        return (
            <Editor
                readOnly={false}
                value={this.state.value}
                onChange={this.onChange}
                plugins={plugins}
                copyMode={true}
            />
        )
    }
}

class SectionBody extends React.Component {
    state = {
        timer: null,
    };


    componentDidMount() {
        let timer = setInterval(this.updateRemoteIfNeeded, 3000);
        this.setState({timer});
    }

    updateRemoteIfNeeded = () => {
        if (this.props.edit) {
            this.props.updateRemoteSlate();
        }
    };

    componentDidUpdate(prevProps) {
        const {section, value, edit} = this.props;

        const valueNotLoaded = section && !value;

        if (valueNotLoaded) {
            this.props.fetchSlateValue(section.pk);
            return;
        }

        if (section) {
            const currentSectionPk = section.pk;
            const prevSectionPk = prevProps.section ? prevProps.section.pk : null;

            const sectionSwitched = currentSectionPk !== prevSectionPk;

            if (prevProps.edit && (!edit || sectionSwitched)) {
                this.props.updateAndSetNullValue();
            }

            if (!prevProps.edit && (edit || sectionSwitched)) {
                this.props.setNullValue();
            }
        }
    }

    componentWillUnmount() {
        if (this.props.edit) {
            this.props.updateAndSetNullValue();

        } else {
            this.props.setNullValue();
        }

        clearInterval(this.state.timer);
    }

    onChange = ({value}) => {
        this.props.updateSectionValue(value);
    };


    render() {
        const {match, classes, section, value, edit, ownedByUser, embed} = this.props;

        if (!section || !value) {
            return (
                <Typography className={classes.loadingMessage}>
                    {edit ? 'editor ' : 'section'} loading...
                </Typography>
            );
        }

        const copyMode = !ownedByUser && edit;

        return (
            <main className={classes.content}>
                {edit && <AndroidInstabilityAlert/>}
                {!embed && <SectionTitleCard match={match} readOnly={!ownedByUser} section={section}/>}
                {!embed && <SectionActionsCard match={match} editMode={edit} section={section}/>}
                {copyMode && <CopyModeEditor value={this.props.value}/>}
                {!copyMode && (
                    <Editor
                        readOnly={!edit}
                        schema={schema}
                        onChange={this.onChange}
                        placeholder={edit ? "Enter some text..." : "Section doesn't have any content..."}
                        value={this.props.value}
                        plugins={plugins}
                    />
                )}
            </main>
            // TODO test plugins order to prevent fuck-ups
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(SectionBody));