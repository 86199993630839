import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import SectionBody from "./SectionBody";
import {getCourseBySlug, getCourseSections} from "../selectors";
import {FETCH_COURSE_BY_SLUG, FETCH_SECTIONS, SET_EMBEDDED_TRUE} from "../reducers/ActionTypes";
import CircularLoadingProgress from "./CircularLoadingProgress";


const styles = theme => ({
    root: {
        display: 'flex',
        wordBreak: 'break-word',
    },
});

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.auth.username,
    course: getCourseBySlug(state, ownProps.match.params.course_slug),
    courseSlug: ownProps.match.params.course_slug,
    sections: getCourseSections(state, ownProps.match.params.course_slug),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCourse: (slug) => dispatch({type: FETCH_COURSE_BY_SLUG, slug}),
    fetchSections: (course_slug) => dispatch({type: FETCH_SECTIONS, course_slug}),
    setEmbeddedTrue: () => dispatch({type: SET_EMBEDDED_TRUE}),
});

class ResponsiveDrawer extends React.Component {
    componentDidMount() {
        const {courseSlug} = this.props;

        this.props.fetchCourse(courseSlug);
        this.props.fetchSections(courseSlug);
        this.props.setEmbeddedTrue();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {courseSlug} = this.props;

        if (courseSlug !== prevProps.courseSlug) {
            this.props.fetchCourse(courseSlug);
            this.props.fetchSections(courseSlug);
        }
    }

    render() {
        const {match, classes, course, sections, currentUser} = this.props;

        if (!course) {
            return <CircularLoadingProgress/>
        }

        const ownedByUser = course.owner.username === currentUser;

        return (
            <div className={classes.root}>
                <SectionBody match={match} sections={sections} ownedByUser={ownedByUser} edit={false} embed={true}/>
            </div>
        );
    }
}

ResponsiveDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(ResponsiveDrawer));