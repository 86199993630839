import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {Button} from "@material-ui/core";
import {OPEN_REGISTER} from "../reducers/ActionTypes";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {Link} from 'react-router-dom';
import Footer from './Footer';


const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: 0,
    },
    gridContainer: {
        padding: 20,
        [theme.breakpoints.up('xl')]: {
            maxWidth: '85%',
            marginRight: 'auto',
            marginLeft: 'auto'
        },
    },
    halfWidthImage: {
        maxWidth: '100%',
        height: 'auto',
        display: 'block'
    },
    publicationWidthImage: {
        maxWidth: '70%',
        height: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    featureImageItem: {
        height: '100px',
        paddingTop: '0px',
        paddingBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '300px'
    },
    fullHeightItem: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            height: `calc(100vh - ${theme.sizes.appBarSize})`,
        },
    },
    item: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    itemWithMargin: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 30,
    },
    text: {
        color: 'white',
    },
    halfWidthTextBlock: {
        [theme.breakpoints.up('lg')]: {
            maxWidth: '800px',
        },
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    fullWidthTextBlock: {
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    button: {
        marginTop: 20,
    },
    "@global": {
        body: {
            backgroundColor: "green",
            height: "100%"
        },
    }
});

const mapDispatchToProps = (dispatch) => ({
    openRegister: () => dispatch({type: OPEN_REGISTER}),
});

const mapStateToProps = (state) => ({
    username: state.auth.username
});

class Home extends React.Component {
    render() {
        const {classes, username} = this.props;
        const direction = 'row';
        const justify = 'center';
        const alignItems = 'center';

        return (
            <React.Fragment>
                {Boolean(username) ? <Redirect to={`/@${username}/bookmarks`}/> : null}
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            className={classes.gridContainer}
                            alignItems={alignItems}
                            direction={direction}
                            justify={justify}
                        >
                            <Grid item className={classes.fullHeightItem} xs={12} md={5}>
                                <div className={classes.halfWidthTextBlock}>
                                    <Typography variant="h3" gutterBottom={true} className={classes.text}>
                                        Learn anything without the fear of forgetting
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        Take courses with an algorithm designed to build and maintain a strong
                                        foundation of knowledge.
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        Can't find a course on your specific topic? Make study notes and you will
                                        remember them for life.
                                    </Typography>
                                    <Button variant="contained" color='secondary' size='large'
                                            onClick={this.props.openRegister} className={classes.button}>
                                        Get started now!
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item className={classes.fullHeightItem} xs={12} md={7}>
                                <img src={require('../images/landing1.gif')} alt=""
                                     className={classes.halfWidthImage}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{background: 'white'}}>
                        <Grid
                            container
                            className={classes.gridContainer}
                            alignItems={alignItems}
                            direction={direction}
                            justify={justify}
                        >
                            <Grid item xs={12} md={7} className={classes.itemWithMargin}>
                                <img src={require('../images/forgettingCurve2.jpeg')} alt=""
                                     className={classes.halfWidthImage}/>

                            </Grid>
                            <Grid item xs={12} md={5} className={classes.itemWithMargin}>
                                <div className={classes.halfWidthTextBlock}>
                                    <Typography variant="h3" gutterBottom>
                                        It's not just memorization
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        The spaced repetition algorithm was discovered by researchers of human learning in the last century.
                                        They found out that you have to repeat concepts you've learned with increasing intervals.
                                        With each active repetition your memory becomes deeper and lasts longer.
                                        After just a few repetitions your memory can last for years.
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        On the contrary, traditional education makes you cram before exams.
                                        Once you've passed your exams they won't remind you to review what you've learned.
                                        No wonder we remember so little after years!
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        And it starts to work since day 1. The next day you'll remember what you've
                                        learned yesterday better. You'll have more confidence to build up on that
                                        knowledge.
                                    </Typography>
                                    {/*<Button variant="contained" color='secondary' size='large'*/}
                                            {/*onClick={this.props.openRegister} className={classes.button}>*/}
                                        {/*Try it now!*/}
                                    {/*</Button>*/}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            className={classes.gridContainer}
                            alignItems={alignItems}
                            direction={direction}
                            justify={justify}
                        >
                            <Grid item className={classes.itemWithMargin} xs={12} md={9}>
                                <div className={classes.fullWidthTextBlock}>
                                    <Typography variant="h3" gutterBottom={true} className={classes.text} align="center">
                                        Your memory, expanded by computer
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        LearnItFast combines the two most powerful and robust ways to aid our memory.
                                        The first being organizing knowledge on external medium. And the second is the
                                        spaced repetition algorithm. They work together to achieve holistic
                                        understanding and to make sure you don't lose it.
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        Learning seemingly unrelated things suddenly becomes useful. As the proverb
                                        goes: "Keep it for seven years and you'll always find a use for it."
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        Just imagine how much you will know after learning for 5 years without
                                        forgetting. You could become an expert in many fields.
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        But even for maintaining expertise in one field a true master needs to remember
                                        rarely used but very important things. And the spaced repetition algorithm is
                                        ideal for those.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{background: 'white'}}>
                        <Grid
                            container
                            className={classes.gridContainer}
                            alignItems={alignItems}
                            direction={direction}
                            justify={justify}
                        >
                            <Grid item xs={12} md={5} className={classes.itemWithMargin}>
                                <div className={classes.halfWidthTextBlock}>
                                    <Typography variant="h3" gutterBottom={true}>
                                        Explore courses, <br/>
                                        make courses
                                    </Typography>
                                    <Typography variant="body2" gutterBottom={true}>
                                        Our new medium for learning looks similar to good old online courses. But we
                                        also added test questions throughout the courses. Answer a question once and
                                        we'll add it to your repetitions queue. At the optimal time we'll remind you to
                                        review it again.
                                    </Typography>
                                    <Typography variant="body2" gutterBottom={true}>
                                        Creating courses is as simple as taking a note. Actually you can use it exactly
                                        for that. Make study notes with test questions and don't worry about forgetting.
                                    </Typography>
                                    <Button variant="contained" color='secondary' size='large'
                                            component={Link} to="/catalog/featured" className={classes.button}>
                                        Explore
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={7} className={classes.itemWithMargin}>
                                <img src={require('../images/landing2.gif')} alt=""
                                     className={classes.halfWidthImage}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            className={classes.gridContainer}
                            alignItems={alignItems}
                            direction={direction}
                            justify={justify}
                        >
                            <Grid item className={classes.itemWithMargin} xs={12} md={9}>
                                <div className={classes.fullWidthTextBlock}>
                                    <Typography variant="h3" gutterBottom={true} className={classes.text} align="center">
                                        Suitable for anything
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        You can use LearnItFast for literally anything.
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        Want to memorize some cooking recipes? Easy! Just ask yourself how to make that
                                        delicious pie.
                                    </Typography>
                                    <Typography variant="h6" gutterBottom={true} className={classes.text}>
                                        Want to learn how quantum computing works? Somebody already made a course about
                                        it.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{background: 'white'}} className={classes.item}>
                        <div className={classes.fullWidthTextBlock} style={{marginTop: 30}}>
                            <Typography variant="h3" align='center' gutterBottom>
                                What the press says about spaced repetition
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{background: 'white'}}>
                        <Grid
                            container
                            className={classes.gridContainer}
                            alignItems='flex-start'
                            direction={direction}
                            justify={justify}
                        >
                            <Grid container direction={'column'} item className={classes.item} xs={12} md={4}>
                                <Grid item className={classes.featureImageItem}>
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/7/75/The_Guardian_2018.svg" alt=""
                                     className={classes.publicationWidthImage}/>
                                </Grid>
                                <Grid item>
                                    <div className={classes.halfWidthTextBlock}>
                                        <Typography variant="h6" gutterBottom={true}>
                                            &ldquo;Spaced repetition is simple, but highly effective because it
                                            deliberately hacks the way your brain works.&rdquo; (c)
                                            <a href="https://www.theguardian.com/education/2016/jan/23/spaced-repetition-a-hack-to-make-your-brain-store-information"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> The Guardian</a>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container direction={'column'} item className={classes.item} xs={12} md={4}>
                                <Grid item className={classes.featureImageItem}>
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/0/02/Quartz_logo.png" alt=""
                                     className={classes.publicationWidthImage}/>
                                </Grid>
                                <Grid item>
                                    <div className={classes.halfWidthTextBlock}>
                                        <Typography variant="h6" gutterBottom={true}>
                                            &ldquo;Two things I learned: Spaced repetition works, and that context
                                            makes
                                            it work even better.&rdquo; (c)
                                            <a href="https://qz.com/1211561/how-to-learn-a-language-use-spaced-repetition/"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> Quartz</a>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container direction={'column'} item className={classes.item} xs={12} md={4}>
                                <Grid item className={classes.featureImageItem}>
                                    <img src="https://cdn.elearningindustry.com/wp-content/themes/eli-2015/assets/images/logo.svg" alt=""
                                     className={classes.publicationWidthImage}/>
                                </Grid>
                                <Grid item>
                                    <div className={classes.halfWidthTextBlock}>
                                        <Typography variant="h6" gutterBottom={true}>
                                            &ldquo;So start practicing microlearning and spaced repetition today,
                                            and
                                            see your training have a lasting business impact.&rdquo; (c)
                                            <a href="https://elearningindustry.com/how-microlearning-spaced-repetition-beat-forgetting-curve"
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: "none"}}> eLearning Industry</a>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div style={{background: 'white'}}>
                    <Footer/>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home));
// What happens usually when we watch educational videos or read interesting article?
//     We say "cool, it's useful" and forget it next day. Some of us note main concepts
// and never open it again. Somebody regularly review this video every month but it's
// also not very effective.
//     We offer you tool that help to get better results in long-term learning.
//     Our spaced repetitions algorithm help you learn complex topics and repeat it in most
// effective scientifically proofed way, so you don't lose your knowledge.