import React from "react";
import PasteLinkify from 'slate-paste-linkify'


export default function LinkPlugin(options) {
    return [
        {
            commands: {
                wrapLink(editor, url) {
                    editor.wrapInline({
                        type: 'link',
                        data: {url},
                    });

                    editor.moveToEnd();
                },
                unwrapLink(editor) {
                    editor.unwrapInline('link')
                }
            },
            queries: {
                isLinkActive(editor) {
                    return editor.value.inlines.some(inline => inline.type === 'link');
                },
            },
            renderNode: (props, editor, next) => {
                const {attributes, children, node} = props;

                switch (node.type) {
                    case 'link': {
                        const {data} = node;
                        const url = data.get('url');
                        return (
                            <a {...attributes}
                               onClick={(event) => {
                                   event.preventDefault();
                                   window.open(url);
                               }}
                               href={url}
                            >
                                {children}
                            </a>
                        )
                    }

                    default: {
                        return next()
                    }
                }
            }
        },

        PasteLinkify()
    ]
}