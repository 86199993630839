import React from "react";
import {SIGN_UP} from "../reducers/ActionTypes";
import {connect} from "react-redux";
import {Typography} from "@material-ui/core";
import Redirect from "./Home";

const mapDispatchToProps = dispatch => ({
    registerDemo: (random) => dispatch({
        type: SIGN_UP,
        username: `demo-${random}`,
        email: `demo-${random}@demo.com`,
        password: random
    })
});

const mapStateToProps = state => {
    return {
        username: state.auth.username
    }
};

class Demo extends React.Component {
    componentDidMount() {
        const {username} = this.props;

        if (!username) {
            const random = Math.random().toString(36).substring(2, 15);
            this.props.registerDemo(random);
        }
    }

    render() {
        const {username} = this.props;

        return (
            <React.Fragment>
                {Boolean(username) ? <Redirect to={"/@" + username + "/courses"}/> : null}
                <Typography variant='h4'>Creating demo account...</Typography>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Demo)