import isHotkey from 'is-hotkey'

export default function HotkeyPlugin(options) {
    const {hotkey, editorThunk} = options;

    return {
        onKeyDown(event, editor, next) {
            if (isHotkey(hotkey, event)) {
                event.preventDefault();
                editorThunk(editor)()
            } else {
                return next()
            }
        },
    }
}