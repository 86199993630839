import {
    CLOSE_EDIT_COURSE,
    CLOSE_NEW_COURSE,
    CONCATENATE_COURSES_PAGE,
    FINISH_NEW_COURSE,
    FINISH_UPDATE_COURSE,
    OPEN_EDIT_COURSE,
    OPEN_NEW_COURSE,
    REMOVE_BOOKMARK_FROM_STATE,
    SET_COURSES,
    SET_COURSES_ERROR,
    START_NEW_COURSE,
    START_UPDATE_COURSE,
    UPDATE_USER_BOOKMARKS
} from "./ActionTypes";

export const PAGE_SIZE = 10;

const defaultState = {
    courses: [],
    haveMorePages: true,
    bookmarks: [],
    newOpened: false,
    editOpened: false,
    isCreating: false,
    isUpdating: false,
    updateSlug: null,
    errorText: "",
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_COURSES:
            return {...state, courses: action.courses, haveMorePages: true};
        case CONCATENATE_COURSES_PAGE:

            const numberOfCoursesInPage = action.courses.length;
            const isPageFull = numberOfCoursesInPage === PAGE_SIZE;

            return {
                ...state,
                courses: state.courses.concat(action.courses),
                haveMorePages: isPageFull
            };
        case UPDATE_USER_BOOKMARKS:
            return {
                ...state,
                bookmarks: state.bookmarks.filter(b => b.owner.username !== action.username).concat(action.bookmarks),
            };
        case REMOVE_BOOKMARK_FROM_STATE:
            return {
                ...state,
                bookmarks: state.bookmarks.filter(b => b.pk !== action.bookmarkPk)
            };
        case OPEN_NEW_COURSE:
            return {...state, newOpened: true};
        case CLOSE_NEW_COURSE:
            return {...state, newOpened: false, errorText: ""};
        case OPEN_EDIT_COURSE:
            return {...state, editOpened: true, updateSlug: action.updateSlug};
        case CLOSE_EDIT_COURSE:
            return {...state, editOpened: false, errorText: "", updateSlug: null};
        case START_NEW_COURSE:
            return {...state, isCreating: true, errorText: ""};
        case FINISH_NEW_COURSE:
            return {...state, isCreating: false};
        case START_UPDATE_COURSE:
            return {...state, isUpdating: true, errorText: ""};
        case FINISH_UPDATE_COURSE:
            return {...state, isUpdating: false};
        case SET_COURSES_ERROR:
            return {
                ...state,
                errorText: action.errorText,
            };
        default:
            return state;
    }
};