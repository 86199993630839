import React from "react";
import {Redirect} from "react-router";
import {OPEN_LOGIN} from "../reducers/ActionTypes";
import {connect} from "react-redux";


const mapDispatchToProps = (dispatch) => ({
    openLogin: () => dispatch({type: OPEN_LOGIN}),
});

class ShowLogin extends React.Component {
    componentDidMount() {
        this.props.openLogin();
    }

    render() {
        return <Redirect to={'/'}/>
    }
}

export default connect(null, mapDispatchToProps)(ShowLogin);