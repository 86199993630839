import React from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import LoginPaper from "./Login";
import RegisterPaper from "./Register";
import withWidth from '@material-ui/core/withWidth';
import {CLOSE_LOGIN, CLOSE_REGISTER, OPEN_LOGIN, OPEN_REGISTER} from "../reducers/ActionTypes";
import {Link} from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ExitToApp from "@material-ui/icons/ExitToApp";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const mapStateToProps = (state) => ({
    login: state.auth.loginOpened,
    register: state.auth.registerOpened,
});

const mapDispatchToProps = (dispatch) => ({
    openLogin: () => dispatch({type: OPEN_LOGIN}),
    closeLogin: () => dispatch({type: CLOSE_LOGIN}),
    openRegister: () => dispatch({type: OPEN_REGISTER}),
    closeRegister: () => dispatch({type: CLOSE_REGISTER}),
});

class UnregisteredButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleSignInMenuOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleMenuClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const button_size = "small";
        const {openLogin, closeLogin, openRegister, closeRegister} = this.props;

        const anchorEl = this.state.anchorEl;
        const isMenuOpen = Boolean(anchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={() => {
                    this.handleMenuClose();
                    openLogin()
                }}>Log in</MenuItem>
                <MenuItem onClick={() => {
                    this.handleMenuClose();
                    openRegister()
                }}>Sign Up</MenuItem>
            </Menu>
        );
        return (
            <div>
                <Dialog
                    open={!!this.props.login}
                    onClose={closeLogin}
                    PaperComponent={LoginPaper}
                    PaperProps={{handleClose: closeLogin}}
                    aria-labelledby="draggable-dialog-title"
                >
                    <div/>
                </Dialog>
                <Dialog
                    open={!!this.props.register}
                    onClose={closeRegister}
                    PaperComponent={RegisterPaper}
                    aria-labelledby="draggable-dialog-title"
                >
                    <div/>
                </Dialog>
                <Hidden smDown>
                    <Button size={button_size} color="inherit" component={Link} to={`/catalog/featured`}>
                        Explore
                    </Button>
                    <Button size={button_size} color="inherit" onClick={openLogin}>
                        Log in
                    </Button>
                    <Button size={button_size} variant="outlined" color="inherit" onClick={openRegister}>
                        Sign Up
                    </Button>
                </Hidden>
                <Hidden mdUp>
                    <Button size={button_size} color="inherit" component={Link} to={`/catalog/featured`}>
                        Explore
                    </Button>
                    <IconButton
                        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleSignInMenuOpen}
                        color="inherit"
                    >
                        <ExitToApp/>
                    </IconButton>
                </Hidden>
                {renderMenu}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(UnregisteredButtons));