import React from "react";
import isUrl from 'is-url'
import {getEventRange, getEventTransfer} from 'slate-react'
import {AddPhotoAlternate} from "@material-ui/icons";
import {IconActionButton} from './IconActionButton'
import Dialog from '@material-ui/core/Dialog';
import NewEmbedPaper from './EmbedNodeDataPaper';


export class ImageButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {imagedialog: false};
    }

    handleClose = () => this.setState({imagedialog: false});

    handleSubmitGenerator = (value) => (event) => {
        event.preventDefault();
        const {editor} = this.props;
        if (isImage(value)) {
            insertImage(editor, value);
            this.handleClose();
        }
    };

    render() {
        return (
            <React.Fragment>
                <IconActionButton
                    isActive={false}
                    onClickAction={() => this.setState({imagedialog: true})}
                    icon={<AddPhotoAlternate/>}
                    title='Insert Image'
                />
                <Dialog
                    open={this.state.imagedialog}
                    onClose={this.handleClose}
                    PaperComponent={NewEmbedPaper}
                    PaperProps={{title: "Add new image by URL", handleSubmitGenerator: this.handleSubmitGenerator}}
                    aria-labelledby="draggable-dialog-title"
                >
                    <div/>
                </Dialog>
            </React.Fragment>
        );
    }
}

export function isImage(url) {
    const strippedUrl = url.split('?')[0];
    return (strippedUrl.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export function insertImage(editor, src, target) {
    editor.insertBlock({
        object: "block",
        type: 'image',
        data: {src: src},
    });
}

const onDropOrPaste = (event, editor, next) => {
    const target = getEventRange(event, editor);
    if (!target && event.type === 'drop') return next();

    const transfer = getEventTransfer(event);
    const {type, text} = transfer;

    if (type === 'text' || type === 'html') {
        if (!isUrl(text)) return next();
        if (!isImage(text)) return next();
        insertImage(editor, text);
        return
    }

    next()
};

function ImagesPlugin(options) {
    return {
        renderNode(props, editor, next) {
            const {node, attributes} = props;

            switch (node.type) {
                case 'image':
                    return (
                        <img src={node.data.get("src")} alt=""
                             style={{display: "block", maxWidth: "100%", maxHeight: "30em"}}
                             {...attributes} />
                    );
                default:
                    return next()
            }
        },
        onPaste: onDropOrPaste,
        onDrop: onDropOrPaste,
    };
}

export default ImagesPlugin;