import {
    FINISH_NEW_SECTION,
    REMOTE_SLATE_UPDATED,
    SET_NULL_SLATE_VALUE,
    SET_SECTIONS,
    SET_SECTIONS_ERROR,
    SET_SLATE_VALUE,
    START_NEW_SECTION,
    UPDATE_REMOTE_SLATE,
    UPDATE_SECTION_TITLE,
    UPDATE_SECTION_VALUE,
    ANDROID_WARNING_SHOWN,
} from "./ActionTypes";


const defaultState = {
    sections: [],
    isCreating: false,
    errorText: "",
    slateValue: null,
    remoteSlateNeedUpdate: false,
    slateValueForRemoteUpdate: null,
    androidWarningShown: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_SECTIONS:
            return {...state, sections: action.sections};
        case START_NEW_SECTION:
            return {...state, isCreating: true, errorText: ""};
        case FINISH_NEW_SECTION:
            return {...state, isCreating: false};
        case UPDATE_SECTION_TITLE:
            const pk = action.pk;
            const [targetSection] = state.sections.filter(s => s.pk === pk);
            const otherSections = state.sections.filter(s => s.pk !== pk);
            if (targetSection) {
                const updatedTargetSection = {...targetSection, title: action.title};
                return {...state, sections: [updatedTargetSection, ...otherSections]};
            }
            return state;
        case UPDATE_SECTION_VALUE:
            if (!action.remoteSlateNeedUpdate) {
                return {
                    ...state,
                    slateValue: action.value,
                };
            }
            return {
                ...state,
                slateValue: action.value,
                remoteSlateNeedUpdate: true,
                slateValueForRemoteUpdate: action.value
            };
        case REMOTE_SLATE_UPDATED:
            return {
                ...state,
                remoteSlateNeedUpdate: false,
                slateValueForRemoteUpdate: null
            };
        case SET_SECTIONS_ERROR:
            return {
                ...state,
                errorText: action.errorText,
            };
        case SET_SLATE_VALUE:
            return {
                ...state,
                slateValue: action.value
            };
        case SET_NULL_SLATE_VALUE:
            return {
                ...state,
                slateValue: null
            };
        case UPDATE_REMOTE_SLATE:
            return {
                ...state,
            };
        case ANDROID_WARNING_SHOWN:
            return {
                ...state,
                androidWarningShown: true,
            };
        default:
            return state;
    }
};