import {createLogic} from "redux-logic";
import {
    ANSWER_QUESTION,
    DELETE_ANSWERED_REPETITION,
    ERROR,
    FETCH_REPETITIONS,
    FETCH_REPETITIONS_COUNT,
    SET_REPETITIONS,
    SET_REPETITIONS_COUNT
} from "../reducers/ActionTypes";
import agent from "../agent";
import {dispatchNextInChainAndDone} from "./ChainUtils";


const fetchRepetitionsLogic = createLogic({
    type: FETCH_REPETITIONS,
    process({getState, action}, dispatch, done) {
        agent.Repetitions.get(1, 3)(action.meta.token)
            .then(data => {
                dispatch({type: SET_REPETITIONS, repetitions: data});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => {
                dispatch({type: FETCH_REPETITIONS_COUNT});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const fetchRepetitionsCountLogic = createLogic({
    type: FETCH_REPETITIONS_COUNT,
    process({getState, action}, dispatch, done) {
        agent.Repetitions.count()(action.meta.token)
            .then(data => {
                dispatch({type: SET_REPETITIONS_COUNT, repetitionsCount: data});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

const answerQuestionLogic = createLogic({
    type: ANSWER_QUESTION,
    process({getState, action}, dispatch, done) {
        agent.Answers.post(action.pk, action.isCorrect)(action.meta.token)
            .then(data => {
                dispatch({type: DELETE_ANSWERED_REPETITION, pk: action.pk});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

export default [fetchRepetitionsLogic, fetchRepetitionsCountLogic, answerQuestionLogic];