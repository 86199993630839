import {createLogic} from "redux-logic";
import {LOGOUT, SET_TOKEN_AND_USERNAME, SET_USERNAME} from "../reducers/ActionTypes";
import {dispatchNextInChainAndDone} from "./ChainUtils";

const addTokenToActionLogic = createLogic({
    type: '*',
    transform({getState, action}, next) {
        const existingMeta = action.meta || {};
        const token = window.localStorage.getItem('jwt');

        const meta = {
            ...existingMeta,
            token: token
        };

        next({
            ...action,
            meta
        });
    }
});

const removeTokenLogic = createLogic({
    type: LOGOUT,
    process({getState, action}, dispatch, done, next) {
        window.localStorage.removeItem('jwt');
        dispatchNextInChainAndDone(action, dispatch, done)
    }
});

const setTokenLogic = createLogic({
    type: SET_TOKEN_AND_USERNAME,
    process({getState, action}, dispatch, done) {
        window.localStorage.setItem('jwt', action.token);
        dispatch({type:SET_USERNAME, username: action.username});
        dispatchNextInChainAndDone(action, dispatch, done)
    }
});

export default [addTokenToActionLogic, setTokenLogic, removeTokenLogic]