import {createLogic} from "redux-logic";
import {ERROR, REMOTE_SLATE_UPDATED, UPDATE_REMOTE_SLATE, UPDATE_SECTION_VALUE} from "../reducers/ActionTypes";
import agent from "../agent";
import {dispatchNextInChainAndDone} from "./ChainUtils";

const updateSectionValue = createLogic({
    type: UPDATE_SECTION_VALUE,
    transform({getState, action}, next) {
        const previousValue = getState().sections.slateValue;
        const updateRemote = previousValue.document !== action.value.document;

        next({
            ...action,
            remoteSlateNeedUpdate: updateRemote,
        });
    },

});

const updateRemoteSlate = createLogic({
    type: UPDATE_REMOTE_SLATE,

    process({getState, action}, dispatch, done) {
        const updateNeeded = getState().sections.remoteSlateNeedUpdate;
        if (!updateNeeded) {
            dispatchNextInChainAndDone(action, dispatch, done);
            return
        }

        const document = getState().sections.slateValueForRemoteUpdate.toJSON().document;
        const sectionPk = document.data.sectionKey;
        const nodes = document.nodes;

        agent.Sections.updateValue(sectionPk, nodes)(action.meta.token)
            .then(data => dispatch({type: REMOTE_SLATE_UPDATED}))
            .catch(error => {
                // TODO  add error handling. May fuck-up when fail to update recent changes
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

export default [updateSectionValue, updateRemoteSlate];