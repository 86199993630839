import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography';
import {NavLink} from 'react-router-dom';
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {BOOKMARK_COURSE, DELETE_BOOKMARK, DELETE_COURSE, OPEN_EDIT_COURSE} from "../reducers/ActionTypes";
import {getUserBookmarks} from "../selectors";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import {withRouterAndRef} from "./utils";

const RouterNavLink = withRouterAndRef(NavLink);

const styles = theme => ({});

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.auth.username,
    courseBookmark: getUserBookmarks(state, state.auth.username).find(b => b.course.slug === ownProps.course.slug),
});

const mapDispatchToProps = (dispatch) => ({
    openEdit: (updateSlug) => dispatch({type: OPEN_EDIT_COURSE, updateSlug}),
    deleteCourse: (slug, username) => dispatch({type: DELETE_COURSE, slug, username}),
    bookmarkCourse: (slug) => dispatch({type: BOOKMARK_COURSE, slug}),
    removeBookmark: (bookmarkPk) => dispatch({type: DELETE_BOOKMARK, bookmarkPk})
});

class CoursePreview extends React.Component {
    state = {
        anchorEl: null,
        deleteOpen: false,
    };

    handleCloseDelete = () => {
        this.setState({deleteOpen: false});
    };

    handleMenuOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleMenuClose = () => {
        this.setState({anchorEl: null});
    };

    handleBookmark = (courseBookmark, courseSlug) => {
        if (!courseBookmark) {
            this.props.bookmarkCourse(courseSlug);
            this.handleMenuClose();
        } else {
            this.props.removeBookmark(courseBookmark.pk);
            this.handleMenuClose();
        }
    };

    render() {
        const {course, courseBookmark, currentUser} = this.props;
        const username = course.owner.username;
        const ownedByUser = username === currentUser;
        const {title, description, slug, updated_at, created_at} = course;

        const anchorEl = this.state.anchorEl;
        const isMenuOpen = Boolean(anchorEl);

        const myCourseActions = (
            <React.Fragment>
                <Tooltip title={'Course Menu'}>
                    <IconButton onClick={this.handleMenuOpen}>
                        <MoreVertIcon/>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={isMenuOpen}
                    onClose={this.handleMenuClose}
                >
                    <MenuItem onClick={() => {
                        this.props.openEdit(slug);
                        this.handleMenuClose();
                    }}>
                        Edit title and description
                    </MenuItem>
                    <MenuItem onClick={() => {
                        this.setState({deleteOpen: true});
                        this.handleMenuClose();
                    }}>
                        Delete course
                    </MenuItem>
                </Menu>
                <DeleteConfirmationDialog
                    open={this.state.deleteOpen}
                    closeHandler={this.handleCloseDelete}
                    actionHandler={() => {
                        this.props.deleteCourse(slug, username);
                        this.handleMenuClose();
                    }}
                    itemName={<b>{title}</b>}
                />
            </React.Fragment>
        );

        const bookmarkCourseActions = (
            <Tooltip title={!!courseBookmark ? 'Remove bookmark' : 'Add to bookmarks'}>
                <IconButton onClick={() => this.handleBookmark(courseBookmark, slug)}>
                    <BookmarkIcon color={!!courseBookmark ? 'primary' : 'action'}/>
                </IconButton>
            </Tooltip>
        );

        return (
            <Card elevation={1} square={true}>
                <CardHeader
                    title={
                        <CardActionArea component={RouterNavLink} to={`/@${username}/${slug}/${ownedByUser ? "edit" : ""}`}>
                            <Grid container justify="space-between">
                                <Grid item xs={7}>
                                    <Typography gutterBottom variant="h6">
                                        {title}
                                    </Typography>
                                    <Typography component="p">
                                        {description ? description : "No course description..."}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography component="p" noWrap>
                                        {username}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Updated on {updated_at.slice(0, 10)}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Created on {created_at.slice(0, 10)}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Views: {course.views}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    }
                    action={ownedByUser ? myCourseActions : bookmarkCourseActions}
                />
            </Card>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CoursePreview));
