import React from "react";
import {connect} from 'react-redux';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import {ANSWER_NODE, QUESTION_BLOCK, QUESTION_NODE} from "./QuestionsPlugin";
import {withStyles} from "@material-ui/core";
import {ANSWER_QUESTION, FETCH_ITEM, FETCH_REPETITIONS, OPEN_LOGIN} from "../../reducers/ActionTypes";
import {getItemRepetitionTime} from "../../selectors";
import PlaceholderPlugin from 'slate-react-placeholder';
import _ from 'lodash/core';
import DeleteIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {chainActionsThatUseLogic} from "../../logic/ChainUtils";

const styles = theme => ({
    card: {
        marginTop: '10px',
        marginBottom: '10px',
        borderLeft: '6px solid #4caf50',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        position: 'relative',
    },
    cardContent: {
        padding: '0 10px 5px 10px'
    },
    divider: {
        // marginTop: '5px',
    },
    questionContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        marginBottom: '5px'
    },
    unselectable: {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        '-o-user-select': 'none',
        userSelect: 'none',
    },
    questionContent: {
        color: '',
        display: 'inline-block',
        width: '100%',
        fontSize: '16px',
    },
});


const mapDispatchToProps = (dispatch) => ({
    postAnswer: (pk, isCorrect) => dispatch(
        chainActionsThatUseLogic([{type: ANSWER_QUESTION, pk, isCorrect}, {type: FETCH_REPETITIONS}])
    ),
    openLogin: () => dispatch({type: OPEN_LOGIN}),
    fetchItem: (pk) => dispatch({type: FETCH_ITEM, pk})
});

const mapStateToProps = (state, ownProps) => ({
    loggedIn: !!state.auth.username,
    embedded: state.auth.embedded,
    nextRepeat: getItemRepetitionTime(state, ownProps.node.data.get("pk"))
});

class QuestionBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answered: false,
            answerCorrect: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const questionPk = this.props.node.data.get("pk");
        const prevQuestionPk = prevProps.node.data.get("pk");

        const questionChanged = questionPk !== prevQuestionPk;

        const readonlyState = this.props.editor.props.readOnly;
        const prevReadOnlyState = prevProps.editor.props.readOnly;

        const switchedToPreview = (readonlyState === true) && (prevReadOnlyState === false);

        if (questionChanged || switchedToPreview) {

            this.setState({
                answered: false,
                answerCorrect: null
            });
        }
    }

    showAnswer = () => {
        this.setState({answered: true});
        this.props.fetchItem(this.props.node.data.get("pk"));
    };

    setAnswerCorrectness = (answerCorrect, node) => {
        if (this.props.loggedIn) {
            this.props.postAnswer(node.data.get("pk"), answerCorrect);
            this.setState({answerCorrect: answerCorrect});
        } else {
            if (!this.props.embedded) {
                this.props.openLogin();
            } else {
                const {protocol, hostname, port} = window.location;
                const loginURL = `${protocol}//${hostname}${!!port ? `:${port}` : ``}/login`;
                window.open(loginURL);
            }

        }

    };

    render() {
        const {classes, children, node, editor} = this.props;

        const editingContent = (
            <CardContent className={classes.cardContent}>
                <Typography color="textSecondary" variant="caption" className={classes.unselectable}>
                    Question:
                </Typography>
                <div className={classes.questionContainer}>
                    <span className={classes.questionContent}>{children[0]}</span>
                </div>
                <Divider className={classes.divider}/>
                <Typography color="textSecondary" variant="caption" className={classes.unselectable}>
                    Answer:
                </Typography>
                <div className={classes.questionContainer}>
                    <span className={classes.questionContent}>{children[1]}</span>
                </div>
            </CardContent>
        );

        const answeredPreviewContent = (
            <CardContent className={classes.cardContent}>
                <Typography color="textSecondary" variant="caption" gutterBottom className={classes.unselectable}>
                    Question:
                </Typography>
                {children[0]}
                <Divider/>
                <Typography color="textSecondary" variant="caption" gutterBottom className={classes.unselectable}>
                    Answer:
                </Typography>
                {children[1]}
            </CardContent>
        );

        const unansweredPreviewContent = (
            <CardContent className={classes.cardContent}>
                <Typography color="textSecondary" variant="caption" gutterBottom className={classes.unselectable}>
                    Question:
                </Typography>
                {children[0]}
            </CardContent>
        );

        const {answered} = this.state;
        const {nextRepeat} = this.props;
        const forEdit = !editor.props.readOnly;

        let cardContent;

        if (forEdit) {
            cardContent = editingContent;

        } else if (answered && nextRepeat) {
            cardContent = answeredPreviewContent;

        } else {
            cardContent = unansweredPreviewContent
        }

        const editingActions = (
            <div style={{position: "absolute", top: 0, right: 0,}} contentEditable={false}>
                <Tooltip title="Delete question and question progress">
                    <IconButton
                        size='small'
                        onClick={() => editor.removeNodeByKey(node.key)}
                        aria-label="delete"
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );

        const showAnswerButton = (
            <CardActions className={classes.unselectable}>
                <Button
                    onClick={this.showAnswer}
                    size="small"
                    color="primary"
                    variant="contained"
                >
                    Show answer
                </Button>
            </CardActions>
        );

        const checkAnswerButtons = (
            <CardActions className={classes.unselectable}>
                <Typography color="textSecondary" variant="caption" style={{marginRight: 'auto'}}>
                    Check your answer (repeat in):
                </Typography>
                <Button
                    onClick={() => this.setAnswerCorrectness(true, node)}
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    I was right! ({nextRepeat})
                </Button>
                <Button
                    onClick={() => this.setAnswerCorrectness(false, node)}
                    size="small"
                    variant="contained"
                    color="secondary"
                >
                    Whoops! (5min)
                </Button>
            </CardActions>
        );

        const brilliant = (
            <CardActions className={classes.unselectable}>
                <div style={{marginLeft: 15}}>
                    <Typography variant="h6" color="primary">Brilliant!</Typography>
                </div>
            </CardActions>
        );


        const nextTime = (
            <CardActions className={classes.unselectable}>
                <div style={{marginLeft: 15}}>
                    <Typography variant="h6" color="secondary">Next time!</Typography>
                </div>
            </CardActions>
        );

        const loadingAnswer = (
            <CardActions className={classes.unselectable}>
                <Typography>
                    Loading...
                </Typography>
            </CardActions>
        );

        const {answerCorrect} = this.state;

        let cardActions;
        const answerCorrectnessNotChecked = (answerCorrect == null);

        if (forEdit) {
            cardActions = editingActions

        } else if (!answered) {
            cardActions = showAnswerButton

        } else if (!nextRepeat) {
            cardActions = loadingAnswer

        } else if (answerCorrectnessNotChecked) {
            cardActions = checkAnswerButtons

        } else if (answerCorrect) {
            cardActions = brilliant

        } else {
            cardActions = nextTime
        }

        return (
            <Card elevation={1} className={classes.card}>
                {cardContent}
                {cardActions}
            </Card>
        )
    }
}

const Question = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuestionBlock));

export function RenderPlugin(options) {
    return {
        renderNode(props, editor, next) {
            const {children, node} = props;

            if (node.type === QUESTION_BLOCK) {
                return <Question editor={editor} children={children} node={node}/>;
            } else {
                return next()
            }
        }
    }
}

export default function RenderQuestionPlugin(options) {
    return [
        RenderPlugin(),
        {
            queries: {
                isQuestionContentNode: (editor, node) => _.isEqual(node.toJSON(), QUESTION_NODE) && !editor.props.readOnly,
                isAnswerContentNode: (editor, node) => _.isEqual(node.toJSON(), ANSWER_NODE) && !editor.props.readOnly,
            }
        },
        PlaceholderPlugin({
            placeholder: 'Add text, images, etc...',
            when: 'isQuestionContentNode',
            style: {color: '#A5A5A5', opacity: '1'},
        }),
        PlaceholderPlugin({
            placeholder: 'Add text, images, etc...',
            when: 'isAnswerContentNode',
            style: {color: '#A5A5A5', opacity: '1'},
        }),
    ]
}