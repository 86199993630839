/**
 * Generates hsl color from arbitrary string
 * @returns {string} hsl color string
 * @param str - The arbitrary string
 */
export const stringToAvatarColor = (str) => {
    return stringToHslColor(str, 60, 48)
};

/**
 * Generates hsl color from arbitrary string
 * @returns {string} hsl color string
 * @param str - The arbitrary string
 * @param saturation - Color saturation
 * @param lightness - Color lightness
 */
export function stringToHslColor(str, saturation, lightness) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

/**
 * Generates time strings from number of seconds
 * Output like "1:01" or "4:03:59" or "123:03:59"
 * @param secondsNumber
 * @returns {string}
 */
export const formatSeconds = (secondsNumber) => {
    const hours = ~~(secondsNumber / 3600);
    const minutes = ~~((secondsNumber % 3600) / 60);
    const seconds = ~~secondsNumber % 60;

    let ret = "";

    if (hours > 0) {
        ret += "" + hours + ":" + (minutes < 10 ? "0" : "");
    }

    ret += "" + minutes + ":" + (seconds < 10 ? "0" : "");
    ret += "" + seconds;
    return ret;
};

export function setPreviewSectionUrl(pk, props) {
    const username = props.match.params.username;
    const course_slug = props.match.params.course_slug;
    const cleanPk = pk ? pk : "";
    props.history.replace(`/@${username}/${course_slug}/${cleanPk}`);
}

export function setEditSectionUrl(pk, props) {
    const username = props.match.params.username;
    const course_slug = props.match.params.course_slug;
    const cleanPk = pk ? pk : "";
    props.history.replace(`/@${username}/${course_slug}/edit/${cleanPk}`);
}