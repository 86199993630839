import React from "react";
import {Redirect} from "react-router";
import {OPEN_ONBOARDING} from "../reducers/ActionTypes";
import {connect} from "react-redux";


const mapDispatchToProps = (dispatch) => ({
    openOnboarding: () => dispatch({type: OPEN_ONBOARDING}),
});

class ShowOnboarding extends React.Component {
    componentDidMount() {
        this.props.openOnboarding();
    }

    render() {
        return <Redirect to={'/'}/>
    }
}

export default connect(null, mapDispatchToProps)(ShowOnboarding);