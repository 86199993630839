export const ERROR = "ERROR";


// Sign in actions
export const SET_TOKEN_AND_USERNAME = "SET_TOKEN_AND_USERNAME";
export const SET_USERNAME = "SET_USERNAME";
export const LOGOUT = "LOGOUT";
export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const INITIALIZE_USER = "INITIALIZE_USER";
export const FINISH_SIGN = "FINISH_SIGN";
// UI actions
export const OPEN_LOGIN = "OPEN_LOGIN";
export const CLOSE_LOGIN = "CLOSE_LOGIN";
export const OPEN_REGISTER = "OPEN_REGISTER";
export const CLOSE_REGISTER = "CLOSE_REGISTER";
// Error actions
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
//Onboarding actions
export const OPEN_ONBOARDING = "OPEN_ONBOARDING";
export const CLOSE_ONBOARDING = "CLOSE_ONBOARDING";
//Embed actions
export const SET_EMBEDDED_TRUE = "SET_EMBEDDED_TRUE";


// Courses actions
export const FETCH_USER_COURSES = "FETCH_USER_COURSES";
export const FETCH_COURSES_PAGE = "FETCH_COURSES_PAGE";
export const FETCH_COURSE_BY_SLUG = "FETCH_COURSE_BY_SLUG";
export const SET_COURSES = "SET_COURSES";
export const CONCATENATE_COURSES_PAGE = "CONCATENATE_COURSES_PAGE";
export const START_NEW_COURSE = "START_NEW_COURSE";
export const FINISH_NEW_COURSE = "FINISH_NEW_COURSE";
export const START_UPDATE_COURSE = "START_UPDATE_COURSE";
export const FINISH_UPDATE_COURSE = "FINISH_UPDATE_COURSE";
export const NEW_COURSE = "NEW_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";

export const FETCH_BOOKMARKS = "FETCH_BOOKMARKS";
export const UPDATE_USER_BOOKMARKS = "UPDATE_USER_BOOKMARKS";
export const BOOKMARK_COURSE = "BOOKMARK_COURSE";
export const DELETE_BOOKMARK = "DELETE_BOOKMARK";
export const REMOVE_BOOKMARK_FROM_STATE = "REMOVE_BOOKMARK_FROM_STATE";
// UI actions
export const OPEN_NEW_COURSE = "OPEN_NEW_COURSE";
export const CLOSE_NEW_COURSE = "CLOSE_NEW_COURSE";
export const OPEN_EDIT_COURSE = "OPEN_EDIT_COURSE";
export const CLOSE_EDIT_COURSE = "CLOSE_EDIT_COURSE";
// Error actions
export const SET_COURSES_ERROR = "SET_COURSES_ERROR";


// Sections actions
export const FETCH_SECTIONS = "FETCH_SECTIONS";
export const SET_SECTIONS = "SET_SECTIONS";
export const START_NEW_SECTION = "START_NEW_SECTION";
export const FINISH_NEW_SECTION = "FINISH_NEW_SECTION";
export const NEW_SECTION = "NEW_SECTION";
export const UPDATE_SECTION_TITLE = "UPDATE_SECTION_TITLE";
export const MOVE_SECTION = "MOVE_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
// Error actions
export const SET_SECTIONS_ERROR = "SET_SECTIONS_ERROR";


// Slate value actions
export const UPDATE_SECTION_VALUE = "UPDATE_SECTION_VALUE";
export const REMOTE_SLATE_UPDATED = "REMOTE_SLATE_UPDATED";
export const UPDATE_REMOTE_SLATE = "UPDATE_REMOTE_SLATE";
export const LOAD_SLATE_VALUE = "LOAD_SLATE_VALUE";
export const SET_SLATE_VALUE = "SET_SLATE_VALUE";
export const SET_NULL_SLATE_VALUE = "SET_NULL_SLATE_VALUE";


export const SET_REPETITIONS = "SET_REPETITIONS";
export const FETCH_REPETITIONS = "FETCH_REPETITIONS";
export const SET_REPETITIONS_COUNT = "SET_REPETITIONS_COUNT";
export const FETCH_REPETITIONS_COUNT = "FETCH_REPETITIONS_COUNT";
export const ANSWER_QUESTION = "ANSWER_QUESTION";
export const DELETE_ANSWERED_REPETITION = "DELETE_ANSWERED_REPETITION";


export const ANDROID_WARNING_SHOWN = "ANDROID_WARNING_SHOWN";

// Items actions
export const SET_ITEM = "SET_ITEM";
export const FETCH_ITEM = "FETCH_ITEM";
