import React from "react";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import {BoldButton} from "./BoldPlugin";
import {NewQuestionButton} from "./QuestionsPlugin";
import {HeaderOneButton, HeaderTwoButton} from "./HeadersPlugin";
import {CodeButton} from "./CodePlugin";
import {ItalicButton} from "./ItalicPlugin";
import {ImageButton} from "./ImagesPlugin";
import {YouTubeButton} from "./YouTubePlugin";
import {LatexButton} from "./LaTeXPlugin";
import {Hidden, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";

const styles = theme => ({
    editorToolbarCard: {
        position: 'sticky',
        top: theme.sizes.appBarSize,
        backgroundColor: '#e0e0e0',
        zIndex: theme.zIndex.appBar - 1,
        padding: 0,
    },
    editorToolbarFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "8px",
        maxWidth: "100%",
    },
    editorButtonsFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        maxWidth: "100%",
    },
    editorContentCard: {
        [theme.breakpoints.up('lg')]: {
            margin: '30px auto 50px auto',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            margin: '5%',
        },
        [theme.breakpoints.only('xs')]: {
            margin: '0',
        },
        maxWidth: '800px',
        padding: theme.spacing(2),
        zIndex: theme.zIndex.appBar - 2,
        minHeight: '60vh'
    },
    // TODO: разобраться с этой хуетенью чтобы код не был таким пиздоватым
    editorContentCardPreview: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: '5%',
            marginRight: '5%'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '0',
        },
        maxWidth: '800px',
        zIndex: theme.zIndex.appBar - 2,
        minHeight: '60vh'
    },
    editorContent: {
        fontSize: '17px',
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        maxWidth: '750px',
        wordBreak: 'break-word',
    },
    actionButtons: {
        marginLeft: "auto",
    }
});

const mapStateToProps = (state) => ({
    remoteSlateNeedUpdate: state.sections.remoteSlateNeedUpdate
});

const RawEditorWithToolbar = (props) => {
    const {classes, children, editor, remoteSlateNeedUpdate} = props;
    const {copyMode} = editor.props;

    const editorToolBar = (
            <div className={classes.editorToolbarFlex}>
                <div className={classes.editorButtonsFlex}>
                    <BoldButton editor={editor}/>
                    <ItalicButton editor={editor}/>
                    <HeaderOneButton editor={editor}/>
                    <HeaderTwoButton editor={editor}/>
                    <CodeButton editor={editor}/>
                    {/*<FormatListNumbered/>*/}
                    {/*<FormatListBulleted/>*/}
                    <ImageButton editor={editor}/>
                    <YouTubeButton editor={editor}/>
                    <LatexButton editor={editor}/>
                </div>
                <Hidden xsDown>
                    <Typography style={{marginLeft: '10px'}}>
                        {remoteSlateNeedUpdate ? <u style={{color: "gray"}}>Saving...</u> :
                            <u style={{color: "gray"}}>All changes saved</u>}
                    </Typography>
                </Hidden>
                <div className={classes.actionButtons}>
                    <NewQuestionButton editor={editor}/>
                </div>
            </div>
    );

    const copyModeToolbar = (
        <CardActions>
            <Typography style={{marginLeft: '10px', color: "gray"}}>
                Copy content from this section and paste it to yours
            </Typography>
        </CardActions>
    );

    return (
        <div>
            {!editor.readOnly && (
                <React.Fragment>
                    <Card elevation={1} square={true} className={classes.editorToolbarCard}>
                        {!copyMode && editorToolBar}
                        {copyMode && copyModeToolbar}
                    </Card>
                    <Card square={true} className={classes.editorContentCard}>
                        <CardContent className={classes.editorContent}>
                            {children}
                        </CardContent>
                    </Card>
                </React.Fragment>
            )}
            {editor.readOnly && (
                <div className={classes.editorContentCardPreview}>
                    <CardContent className={classes.editorContent}>
                        {children}
                    </CardContent>
                </div>
            )}
        </div>
    )
};

const EditorWithToolbar = connect(mapStateToProps)(withStyles(styles)(RawEditorWithToolbar));

export default function ToolbarPlugin(options) {
    return {
        renderEditor(props, editor, next) {
            const children = next();

            return (
                <EditorWithToolbar children={children} editor={editor}/>
            )
        },
    }
}