import {Block} from 'slate'
import {QUESTION_BLOCK} from "./QuestionsPlugin";

// TODO import this constants from plugins
const contentTypes = [
    {type: 'paragraph'},
    {type: 'h1'},
    {type: 'h2'},
    {type: 'code'},
    {type: 'image'},
    {type: 'youtube'}
];

const textTypes = [
    {type: 'paragraph'},
    {type: 'h1'},
    {type: 'h2'},
];

const questionType = {type: QUESTION_BLOCK};

const childErrorTypes = ['child_object_invalid', 'child_min_invalid', 'child_max_invalid', 'child_type_invalid',
    'child_unknown', 'first_child_object_invalid', 'first_child_type_invalid', 'last_child_object_invalid',
    'last_child_type_invalid'];

const normalizeFirstAndLastLines = (editor, {code, node, child, index}) => {
    switch (code) {
        case 'child_min_invalid': {
            const block = Block.create('paragraph');
            return editor.insertNodeByKey(node.key, index, block);
        }
        case 'last_child_type_invalid': {
            const block = Block.create('paragraph');
            return editor.insertNodeByKey(node.key, node.nodes.size, block);
        }
        default:
            break;
    }
};

const normalizeLinesBeforeAndAfter = (editor, {code, node, next, index}) => {
    switch (code) {
        case 'next_sibling_type_invalid': {
            const block = Block.create('paragraph');
            return editor.insertNodeByKey(node.key, index + 1, block);
        }
        case 'previous_sibling_type_invalid': {
            const block = Block.create('paragraph');
            return editor.insertNodeByKey(node.key, index, block);
        }
        default:
            break;
    }
};

const deleteForbiddenNodes = (editor, nodes, allowedTypes) => {
    const allowedTypesArray = allowedTypes.map(x => x.type);
    const notAllowedNodes = nodes.filter(node => !allowedTypesArray.includes(node.type));
    notAllowedNodes.forEach(node => editor.removeNodeByKey(node.key));
};

const normalizeQuestionBlock = (editor, {code, node, next, index}) => {
    if (childErrorTypes.includes(code)) {
        deleteForbiddenNodes(editor, node.nodes, [{type: 'question'}, {type: 'answer'}]);
    }
    if (code === 'child_type_invalid' || code === 'child_min_invalid') {
        const nodes = editor.value.document.getNode(node.key).nodes;
        if (nodes.size === 0) {
            const block = Block.create('question');
            return editor.insertNodeByKey(node.key, 0, block);
        }
        if (nodes.size === 1) {
            const type = nodes.get(0).type;
            if (type === "question") {
                const block = Block.create('answer');
                return editor.insertNodeByKey(node.key, 1, block);
            }
            if (type === "answer") {
                const block = Block.create('question');
                return editor.insertNodeByKey(node.key, 0, block);
            }
        }
    }
    normalizeLinesBeforeAndAfter(editor, {code, node, next, index});
};

const normalizeQuestionOrAnswer = (editor, {code, node, child, index}) => {
    normalizeFirstAndLastLines(editor, {code, node, child, index});
    if (childErrorTypes.includes(code)) {
        deleteForbiddenNodes(editor, node.nodes, contentTypes);
    }
};

const normalizeCode = (editor, {code, node, next, index}) => {
    normalizeFirstAndLastLines(editor, {code, node, index});
    
    const singleInvalidChild = (code === 'child_type_invalid') && (node.nodes.size === 1);

    if (singleInvalidChild) {
        return editor.removeNodeByKey(node.key);
    }

    if (childErrorTypes.includes(code)) {
        deleteForbiddenNodes(editor, node.nodes, [{type: 'paragraph'}]);
    }
    normalizeLinesBeforeAndAfter(editor, {code, node, next, index});
};

const normalizeDocument = (editor, {code, node, child, index}) => {
    deleteForbiddenNodes(editor, node.nodes, [...contentTypes, questionType]);
    normalizeFirstAndLastLines(editor, {code, node, child, index});
};

const schema = {
    document: {
        nodes: [
            {match: textTypes, min: 1},
            {
                match: [...contentTypes, questionType]
            },
        ],
        last: textTypes,
        normalize: normalizeDocument,
    },
    blocks: {
        unansweredQuestion: {
            nodes: [
                {match: {type: 'question'}, min: 1, max: 1},
                {match: {type: 'answer'}, min: 1, max: 1},
            ]
        },
        questionWithAnswer: {
            nodes: [
                {match: {type: 'question'}, min: 1, max: 1},
                {match: {type: 'answer'}, min: 1, max: 1},
            ]
        },
        questionBlock: {
            nodes: [
                {match: {type: 'question'}, min: 1, max: 1},
                {match: {type: 'answer'}, min: 1, max: 1},
            ],
            previous: textTypes,
            next: textTypes,
            normalize: normalizeQuestionBlock,
        },
        question: {
            nodes: [
                {match: textTypes, min: 1},
                {match: contentTypes}
            ],
            last: textTypes,
            normalize: normalizeQuestionOrAnswer,
        },
        answer: {
            nodes: [
                {match: textTypes, min: 1},
                {match: contentTypes}
            ],
            last: textTypes,
            normalize: normalizeQuestionOrAnswer,
        },
        image: {
            isVoid: true,
            previous: textTypes,
            next: textTypes,
            normalize: normalizeLinesBeforeAndAfter,
        },
        youtube: {
            isVoid: true,
            previous: textTypes,
            next: textTypes,
            normalize: normalizeLinesBeforeAndAfter,
        },
        code: {
            nodes: [
                {match: {type: 'paragraph'}, min: 1},
            ],
            previous: textTypes,
            next: textTypes,
            normalize: normalizeCode,
        }
    },
    inlines: {
        latexInline: {
            idVoid: true,
        },
    },
};

export default schema;