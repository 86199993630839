import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import {ANDROID_WARNING_SHOWN} from "../reducers/ActionTypes";


function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

const mapStateToProps = (state) => ({
    warningShown: state.sections.androidWarningShown,
});

const mapDispatchToProps = (dispatch) => ({
    closeWarning: () => dispatch({type: ANDROID_WARNING_SHOWN}),
});

class AndroidInstabilityAlert extends React.Component {
    state = {
        open: (getMobileOperatingSystem() === "Android") && !this.props.warningShown,
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.closeWarning();
    };

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Editing not supported for Android (yet) 😢"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Section editor doesn't support Android yet. Use at your own risk.
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description-2">
                            Supported platforms: desktop computers, iOS.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AndroidInstabilityAlert);