import React from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import SubjectIcon from "@material-ui/icons/Subject";
import {withStyles} from "@material-ui/core";
import { setPreviewSectionUrl, setEditSectionUrl } from "../utils";


const styles = theme => ({
    sectionActive: {
        backgroundColor: '#E0E0E0',
        borderLeft: '3px solid #4caf50',
        padding: '8px 13px',
    },
});


const mapStateToProps = (state, ownProps) => ({
    currentSectionPk: Number(ownProps.match.params.section_pk),
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

class SectionPreview extends React.Component {

    render() {
        const {section, key, handleDrawerToggle, currentSectionPk, classes, edit} = this.props;
        const isSectionCurrent = section.pk === currentSectionPk;
        return (
            <ListItem button key={key} alignItems="flex-start"
                      className={isSectionCurrent ? classes.sectionActive : null}
                      onClick={() => {
                          if (edit) {
                              setEditSectionUrl(section.pk, this.props);
                          } else {
                              setPreviewSectionUrl(section.pk, this.props);
                          }
                          if (handleDrawerToggle) {
                              handleDrawerToggle();
                          }
                      }
                      }>
                <ListItemAvatar>
                    <Avatar>
                        <SubjectIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"Section " + (section.order + 1) + ":"}
                    secondary={
                        <React.Fragment>
                            <Typography component="span" color="textPrimary">
                                {section.title.slice(0, 44)}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SectionPreview));
