import React from "react";
import {IconActionButton} from "./IconActionButton";
import {Functions} from "@material-ui/icons";
import 'katex/dist/katex.min.css';
import {InlineMath} from 'react-katex';
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import EmbedNodeDataPaper from "./EmbedNodeDataPaper";
import Link from "@material-ui/core/Link";
import Button from '@material-ui/core/Button';

export const LATEX_INLINE = "latexInline";

export class LatexButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {openDialog: false};
    }

    handleClose = () => this.setState({openDialog: false});

    render() {
        const {editor} = this.props;

        return (
            <React.Fragment>
                <IconActionButton
                    isActive={false}
                    onClickAction={() => this.setState({openDialog: true})}
                    icon={<Functions/>}
                    title='LaTeX'
                />
                <LatexDialogue
                    openDialog={this.state.openDialog}
                    editor={editor}
                    handleClose={this.handleClose}
                />
            </React.Fragment>
        )
    }
}

class LatexDialogue extends React.Component {
    handleSubmitFormula = (formula) => (event) => {
        event.preventDefault();
        const {editor, node} = this.props;
        if (!node) {
            editor.insertInline({
                object: "inline",
                type: LATEX_INLINE,
                data: {formula},
            })

        } else {
            editor.setNodeByKey(node.key, {data: {formula}});
        }

        this.props.handleClose();
    };

    handleDeleteFormula = (event) => {
        event.preventDefault();
        const {editor, node} = this.props;
        editor.removeNodeByKey(node.key);
        this.props.handleClose();
    };

    render() {
        let nodeText = undefined;

        const {node} = this.props;
        if (node && node.data) {
            nodeText = node.data.get("formula");
        }

        const latexDialogTitle = (
            <div style={{textAlign: "center"}}>
                Enter a <Link target="_blank" rel="noopener noreferrer"
                              href={'https://users.dickinson.edu/~richesod/latex/latexcheatsheet.pdf'}>LaTeX</Link> expression (<Link
                target="_blank" rel="noopener noreferrer" href="https://katex.org/docs/supported.html">Supported
                functions</Link>)
            </div>
        );

        return (
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.handleClose}
                PaperComponent={EmbedNodeDataPaper}
                PaperProps={{
                    title: latexDialogTitle,
                    handleSubmitGenerator: this.handleSubmitFormula,
                    handleDelete: this.handleDeleteFormula,
                    previousValue: nodeText,
                    inputLabel: "LaTeX"
                }}
                aria-labelledby="draggable-dialog-title"
            >
                <div/>
            </Dialog>
        )
    }
}

class EditableLatex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {openDialog: false};
    }

    handleClose = () => this.setState({openDialog: false});

    render() {
        const {children, node, editor, compiledLatex} = this.props;

        return (
            <React.Fragment>
                <Tooltip title="Edit" aria-label="Edit">
                    <Button
                        onClick={() => this.setState({openDialog: true})}
                        variant="outlined"
                        style={{textTransform: "none"}}
                    >
                        {compiledLatex}
                    </Button>
                </Tooltip>
                {children}
                <LatexDialogue
                    openDialog={this.state.openDialog}
                    node={node}
                    editor={editor}
                    handleClose={this.handleClose}
                />
            </React.Fragment>
        )
    }
}

function LatexInline(props) {
    const {children, editor, node} = props;

    const forEdit = !editor.props.readOnly;

    const nodeText = node.data.get("formula");

    const compiledLatex = (
        <InlineMath>
            {nodeText}
        </InlineMath>
    );

    const editableLatex = (
        <EditableLatex
            compiledLatex={compiledLatex}
            node={node}
            editor={editor}
            children={children}
        />
    );

    return forEdit ? editableLatex : compiledLatex;
}

export default function LaTeXPlugin(options) {
    return {
        renderNode(props, editor, next) {
            const {children, node} = props;

            if (node.type === LATEX_INLINE) {
                return <LatexInline editor={editor} children={children} node={node}/>;
            } else {
                return next()
            }
        },
    }
}