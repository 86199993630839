import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50 %, -50 %)',
    }
});

function CircularLoadingProgress(props) {
    const {classes} = props;
    return (
        <div>
            <CircularProgress className={classes.progress}/>
        </div>
    );
}

export default withStyles(styles)(CircularLoadingProgress);