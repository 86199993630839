import HotkeyPlugin from "./HotkeyPlugin";
import React from "react";
import {IconActionButton} from "./IconActionButton";
import {LooksOne, LooksTwo} from "@material-ui/icons";
import RenderNodePlugin from "./RenderNodePlugin";
import Divider from "@material-ui/core/Divider";

const HEADER_ONE = 'h1';
const HEADER_TWO = 'h2';
const PARAGRAPH = 'paragraph';


function getEditorThunk(type) {
    return (editor) => () => {
        const isActive = editor.isBlockActive(type);
        const newType = isActive ? PARAGRAPH : type;

        editor.setBlocks(newType);
    }
}

export function HeaderOneButton(props) {
    const {editor} = props;
    const isActive = editor.isBlockActive(HEADER_ONE);


    return (
        <IconActionButton
            isActive={isActive}
            onClickAction={getEditorThunk(HEADER_ONE)(editor)}
            icon={<LooksOne/>}
            title='Heading 1 (Ctrl+1)'
        />
    )
}

export function HeaderTwoButton(props) {
    const {editor} = props;
    const isActive = editor.isBlockActive(HEADER_TWO);

    return (
        <IconActionButton
            isActive={isActive}
            onClickAction={getEditorThunk(HEADER_TWO)(editor)}
            icon={<LooksTwo/>}
            title='Heading 2 (Ctrl+2)'
        />
    )
}

export function HeaderPlugin(options) {

    return [
        HotkeyPlugin(
            {
                hotkey: 'mod+1',
                editorThunk: getEditorThunk(HEADER_ONE)
            }),

        HotkeyPlugin(
            {
                hotkey: 'mod+2',
                editorThunk: getEditorThunk(HEADER_TWO)
            }),

        RenderNodePlugin(
            {
                type: HEADER_ONE,
                render: (attributes, children) => (
                    <React.Fragment>
                        <Divider style={{marginTop: '10px'}}/>
                        <h1 style={{fontFamily: "Open Sans", fontSize: '32px',}} {...attributes}>
                            {children}
                        </h1>
                    </React.Fragment>
                )
            }),

        RenderNodePlugin(
            {
                type: HEADER_TWO,
                render: (attributes, children) => (
                    <h2 style={{fontFamily: "Open Sans", fontSize: '24px',}} {...attributes}>
                        {children}
                    </h2>
                )
            }),
    ]
}
