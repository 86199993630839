import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Footer from './Footer';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: 0
    },
    gridContainer: {
        padding: 0,
    },
    itemWithMargin: {
        padding: '30px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 30,
    },
    fullWidthTextBlock: {
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

export default function Contacts(props) {
    const direction = 'row';
    const justify = 'center';
    const alignItems = 'center';

    const classes = useStyles(props);

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Grid
                        container
                        className={classes.gridContainer}
                        alignItems={alignItems}
                        direction={direction}
                        justify={justify}
                    >
                        <Grid item className={classes.itemWithMargin} xs={12} md={9}>
                            <div className={classes.fullWidthTextBlock}>
                                <Typography variant="h3" gutterBottom align="center">
                                    Contacts
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    Have a question? Encountered a bug? Have any thoughts about LearnItFast?
                                    Say Hi to us! We're friendly and always happy to chat.
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    E-mail: info@learnitfast.io
                                </Typography>
                                <Typography variant="h6" gutterBottom={true}>
                                    Also you can chat with us via Facebook Messenger at our
                                    <a href="https://www.facebook.com/LearnItFast-330289777566981"
                                       target="_blank" rel="noopener noreferrer"
                                       style={{textDecoration: "none"}}> Facebook page</a>.
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <Footer/>
            </div>
        </React.Fragment>
    );
}