import {SET_ITEM} from "./ActionTypes";

const defaultState = {
    items: [],
};

export default (state = defaultState, action) => {
    if (action.type === SET_ITEM) {
        return {
            ...state,
            items: state.items.filter(item => item.pk !== action.item.pk).concat(action.item)
        };
    }

    return state;
};