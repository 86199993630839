import {createLogic} from "redux-logic";
import {ERROR, FETCH_ITEM, SET_ITEM,} from "../reducers/ActionTypes";
import agent from "../agent";
import {dispatchNextInChainAndDone} from "./ChainUtils";

const fetchItemLogic = createLogic({
    type: FETCH_ITEM,
    process({getState, action}, dispatch, done) {
        agent.Items.get(action.pk)(action.meta.token)
            .then(data => {
                dispatch({type: SET_ITEM, item: data});
            })
            .catch(error => {
                dispatch({type: ERROR, error: error});
            })
            .then(() => dispatchNextInChainAndDone(action, dispatch, done));
    }
});

export default [fetchItemLogic];
