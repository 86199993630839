export default function RenderMarkPlugin(options) {
    const {type, render} = options;

    // Return our "plugin" object, containing the `onKeyDown` handler.
    return {
        renderNode: (props, editor, next) => {
            switch (props.node.type) {
                case type:
                    return render(props.attributes, props.children);
                default:
                    return next()
            }
        }
    }
}