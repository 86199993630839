import React from "react";
import {connect} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import SectionPreview from "./SectionPreview";
import {NEW_SECTION} from "../reducers/ActionTypes";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";

const mapDispatchToProps = (dispatch, ownProps) => ({
    newSection: () => dispatch({type: NEW_SECTION, course_slug: ownProps.course.slug}),
});

class ResponsiveDrawer extends React.Component {
    state = {
        expanded: false,
    };

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    render() {
        const {classes, ownedByUser, mobile, sections, course, match, history, edit} = this.props;

        return (
            <div>
                {!mobile && <div className={classes.appBarSpaceDiv}/>}
                <Card elevation={0}>
                    <CardHeader titleTypographyProps={{align: 'left'}}
                                title={!!course ? course.title : ""}/>
                    <CardActions>
                        <Button size="small"
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.expanded}
                                aria-label="Show more"
                                color={'primary'}
                        >
                            show description
                        </Button>
                    </CardActions>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography color="textSecondary">
                                {!!course ? course.description : ""}
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>
                <List>
                    <Divider/>
                    {ownedByUser && (
                        <ListItem button key={"-1"} divider={true}
                                  onClick={this.props.newSection}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Fab color="primary" size="small" aria-label="Add">
                                        <AddIcon/>
                                    </Fab>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="New section"/>
                        </ListItem>
                    )}
                    {sections.map((section) => (
                        <SectionPreview key={section.order} section={section} handleDrawerToggle={this.props.handleDrawerToggle}
                        match={match} history={history} edit={edit}/>
                    ))}
                </List>
            </div>

        );
    }
}

export default connect(null, mapDispatchToProps)(ResponsiveDrawer);