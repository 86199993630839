import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from "../styles/dialogStyle";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {connect} from "react-redux";


const mapStateToProps = (state, ownProps) => ({
    numberOfSections: state.sections.sections.length,
});

const mapDispatchToProps = (dispatch) => ({
});


class SectionMoveDialog extends React.Component {
    constructor (props) {
        super(props);
        this.state = {value: "", validationState: ""}
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
    };

    onSubmit = (event) => {
        event.preventDefault();
        const moveto = parseInt(this.state.value);
        if (moveto && moveto > 0 && moveto < this.props.numberOfSections + 1) {
            this.props.actionHandler(moveto - 1);
            this.props.closeHandler();
        } else {
            this.setState({
                validationState: "Please enter an integer from 1 to " +
                    this.props.numberOfSections
            })
        }
    };

    render() {
        const {open, closeHandler} = this.props;
        return (
            <Dialog
                open={open}
                onClose={closeHandler}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{
                    style: {margin: "24px"}
                }}
                maxWidth="xs"
            >
                <DialogTitle id="alert-dialog-title">Move section</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Enter the position you want to move this section to (a number from 1 to " +
                        this.props.numberOfSections + ")"}
                    </DialogContentText>
                    <form onSubmit={this.onSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="input">New position</InputLabel>
                            <Input id="input" name="input" autoFocus
                                   value={this.state.value} onChange={this.handleChange}
                            />
                        </FormControl>
                        <FormHelperText error={true}>{this.state.validationState}</FormHelperText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onSubmit} color="default">
                        Move
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SectionMoveDialog));