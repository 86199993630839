import { getEventTransfer } from 'slate-react'

const onDropOrPaste = (event, editor, next) => {
    const transfer = getEventTransfer(event);

    const pastingFromSlate = "fragment";
    if (transfer.type !== pastingFromSlate) {
        return next();
    }
    let fragment = transfer.fragment;
    const nodes = fragment.get('nodes');
    if (nodes.size === 1) {
        const oneNode = nodes.get(0);
        if (oneNode.get('type') === "questionBlock") {
            const questionNodes = oneNode.get('nodes');
            if (questionNodes.size === 1) {
                const contentNodes = questionNodes.get(0).get('nodes');
                const newFragment = fragment.set('nodes', contentNodes);
                editor.insertFragment(newFragment);
                return;
            }
        }
    }
    for (let i = 0; i < nodes.size; i++) {
        if (nodes.get(i).get('data')) {
            fragment = fragment.deleteIn(['nodes', i, 'data', 'pk']);
        }
    }
    editor.insertFragment(fragment);
};

function QuestionOnPastePlugin (options) {
    return {
        onPaste: onDropOrPaste,
        onDrop: onDropOrPaste,
    };
}

export default QuestionOnPastePlugin;