import {createLogicMiddleware} from 'redux-logic';
import TokenLogic from "./logic/TokenLogic";
import LoginLogic from "./logic/LoginLogic";
import GeneralErrorLogic from "./logic/GeneralErrorLogic";
import CoursesLogic from "./logic/CoursesLogic";
import SectionsLogic from "./logic/SectionsLogic";
import UpdateSlateLogic from "./logic/UpdateSlateLogic";
import GetSlateLogic from "./logic/GetSlateLogic";
import RepetitionsLogic from "./logic/RepetitionsLogic";

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ItemsLogic from "./logic/ItemsLogic";

// order matters
const logic = [
    ...TokenLogic,
    ...LoginLogic,
    ...CoursesLogic,
    ...SectionsLogic,
    ...UpdateSlateLogic,
    ...GetSlateLogic,
    ...RepetitionsLogic,
    ...GeneralErrorLogic,
    ...ItemsLogic,
];
export const logicMiddleware = createLogicMiddleware(logic);



LogRocket.init(process.env.REACT_APP_LOGROCKET, {
    network: {
        requestSanitizer: request => {
            if (request.headers['Authorization']) {
                request.headers['Authorization'] = "Sanitized";
            }

            if (request.body) {
                let body_obj = JSON.parse(request.body);
                if (body_obj.password1) {
                    body_obj.password1 = "Sanitized";
                }
                if (body_obj.password2) {
                    body_obj.password2 = "Sanitized";
                }
                if (body_obj.password) {
                    body_obj.password = "Sanitized";
                }
                request.body = JSON.stringify(body_obj);
            }

            return request;
        },
        responseSanitizer: response => {
            if (response.body) {
                let body_obj = JSON.parse(response.body);
                if (body_obj.token) {
                    body_obj.token = "Sanitized";
                }
                response.body = JSON.stringify(body_obj);
            }

            return response;
        },
    },
});
setupLogRocketReact(LogRocket);
const LogRocketReduxConfig = {
    actionSanitizer: function (action) {
        let sanitized_action = action;
        if (action.meta && action.meta.token) {
            sanitized_action = {
                ...sanitized_action,
                meta: {...sanitized_action.meta, token: "Sanitized"},
            };
        }
        if (action.token) {
            sanitized_action = {
                ...sanitized_action,
                token: "Sanitized",
            };
        }
        if (action.password) {
            sanitized_action = {
                ...sanitized_action,
                password: "Sanitized",
            };
        }

        return sanitized_action;
    },
};


export const logRocketReduxMiddleware = LogRocket.reduxMiddleware(LogRocketReduxConfig);