import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from "../styles/dialogStyle";
import {SIGN_IN} from "../reducers/ActionTypes";

const mapStateToProps = state => ({
    isSigning: state.auth.isSigning,
    errorText: state.auth.errorText,
});

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch({
        type: SIGN_IN,
        username: username,
        password: password
    })
});

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {username: "", password: ""}
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (!this.props.isSigning) {
            this.props.login(this.state.username, this.state.password);
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Log in
                    </Typography>
                    {/*<SocialLoginButtons classes={classes}/>*/}
                    <form className={classes.form} onSubmit={this.onSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">Username (case-sensitive)</InputLabel>
                            <Input id="username" name="username" autoComplete="username" autoFocus
                                   value={this.state.username}
                                   onChange={(e) => this.setState({username: e.target.value})}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input name="password" type="password" id="password" autoComplete="current-password"
                                   value={this.state.password}
                                   onChange={(e) => this.setState({password: e.target.value})}/>
                        </FormControl>
                        <FormHelperText error={true}>{this.props.errorText}</FormHelperText>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={this.props.isSigning}
                            className={classes.submit}
                            onClick={this.onSubmit}
                        >
                            Sign in
                        </Button>
                    </form>
                </Paper>
            </main>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn));