import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_ROOT;

const encode = encodeURIComponent;
const responseBody = res => res.body;

const tokenPlugin = token => req => {
    if (token) {
        req.set('Authorization', `JWT ${token}`);
    }
};

const requests = {
    del: url => token =>
        superagent.del(`${API_ROOT}${url}`).use(tokenPlugin(token)).then(responseBody),
    get: url => token =>
        superagent.get(`${API_ROOT}${url}`).use(tokenPlugin(token)).then(responseBody),
    patch: (url, body) => token =>
        superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin(token)).then(responseBody),
    post: (url, body) => token =>
        superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin(token)).then(responseBody)
};

const Auth = {
    current: () =>
        requests.get('/user'),
    login: (username, password) =>
        requests.post('/login/', {username, password}),
    register: (username, email, password) =>
        requests.post('/registration/', {username, email, password1: password, password2: password}),
};

const Courses = {
    create: (title, description) =>
        requests.post('/courses', {title, description}),
    byUser: username =>
        requests.get(`/courses/?owner=${encode(username)}`),
    getList: (featured, page, pageSize) =>
        requests.get(`/courses/?featured=${featured}&page=${page}&pagesize=${pageSize}`),
    get: (slug) =>
        requests.get(`/courses/${slug}`),
    update: (slug, title, description) =>
        requests.patch(`/courses/${slug}`, {title, description}),
    del: (slug) =>
        requests.del(`/courses/${slug}`),
};

const Bookmarks = {
    create: course_slug =>
        requests.post(`/bookmarks/?course=${encode(course_slug)}`),
    byUser: username =>
        requests.get(`/bookmarks/?owner=${encode(username)}`),
    del: pk =>
        requests.del(`/bookmarks/${pk}`),
};

const Sections = {
    create: (title, course_slug) =>
        requests.post(`/sections/?course=${encode(course_slug)}`, {title}),
    byCourse: course_slug =>
        requests.get(`/sections/?course=${encode(course_slug)}`),
    get: pk =>
        requests.get(`/sections/${pk}`),
    update: (pk, title, moveto) => {
        if ((moveto || moveto===0) && !title) {
            return requests.patch(`/sections/${pk}/?moveto=${encode(moveto)}`, {});
        } else {
            if (moveto || moveto===0) {
                return requests.patch(`/sections/${pk}/?moveto=${encode(moveto)}`, {title});
            } else {
                return requests.patch(`/sections/${pk}/`, {title});
            }
        }
    },
    updateValue: (pk, value) =>
        requests.patch(`/sections/${pk}`, {value}),
    del: (pk) =>
        requests.del(`/sections/${pk}`),
};

const Items = {
    // create: (title, description) =>
    //     requests.post('/items', {title, description}),

    bySection: sectionKey =>
        requests.get(`/items/?section_pk=${sectionKey}`),

    get: itemKey =>
        requests.get(`/items/${itemKey}`),

    // update: (itemKey, itemType, description) =>
    //     requests.patch(`/items/${itemKey}`, {title, description}),

    // del: (itemKey) =>
    //     requests.del(`/items/${itemKey}`),
};

const Repetitions = {
    get: (page, pageSize) =>
        requests.get(`/repetitions/?page=${page}&pagesize=${pageSize}`),
    count: () =>
        requests.get(`/repetitions/count`),
};

const Answers = {
    post: (pk, isCorrect) => {
        const is_correct = isCorrect ? "True" :"False";
        return requests.post(`/answers/?item_pk=${pk}`, {is_correct});
    },
};


export default {
    Auth,
    Sections,
    Courses,
    Bookmarks,
    Items,
    Repetitions,
    Answers
};