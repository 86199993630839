import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import store from './store'
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route} from 'react-router-dom';
import {config} from 'dotenv';
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from '@material-ui/styles';
import {green} from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import WebFont from 'webfontloader';

config();

WebFont.load({
    google: {
        families: ['Open Sans', 'sans-serif', 'Roboto']
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            ...green,
            contrastText: green[50],
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: "Roboto",
    },
    // TODO replace this magic number by actual appBar or toolbar height
    sizes: {
        appBarSize: '48px'
    },
    overrides: {
        // Name of the component
        MuiTypography: {
            // Name of the rule
            root: {
                wordBreak: 'break-word',
            }
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <Route path="/" component={App}/>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
