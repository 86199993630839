import HotkeyPlugin from "./HotkeyPlugin";
import RenderMarkPlugin from "./RenderMarkPlugin";
import React from "react";
import {IconActionButton} from "./IconActionButton";
import {FormatItalic} from "@material-ui/icons";

const ITALIC = 'italic';

const addItalicThunk = (editor) => () => editor.toggleMark(ITALIC);

export function ItalicButton(props) {
    const {editor} = props;
    const isActive = editor.isMarkActive(ITALIC);

    return <IconActionButton
        isActive={isActive}
        onClickAction={addItalicThunk(editor)}
        icon={<FormatItalic/>}
        title='Italic (Ctrl+I)'
    />
}

export function ItalicPlugin(options) {

    return [
        HotkeyPlugin(
            {
                hotkey: 'mod+i',
                editorThunk: addItalicThunk
            }),

        RenderMarkPlugin(
            {
                type: ITALIC,
                render: (attributes, children) => <em {...attributes}>{children}</em>
            }),
    ]
}